import { usePrevious } from '@swe/shared/hooks';
import { useDevice } from '@swe/shared/tools/media';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { isSSR } from '@swe/shared/utils/environment';
import { pick } from '@swe/shared/utils/object';

import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { AnalyticsAdaptersConfig, useAdapters } from 'common/providers/analytics/adapters';
import {
  AEventMeta,
  AEventType,
  AnalyticsAdapter,
  PushEvent,
  useDeviceId,
  useSession,
} from 'common/providers/analytics/constants';
import { useCurrentUser } from 'common/providers/user';
import { useRouterPathname, useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';

import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useStoreInfo } from 'common/use-cases/use-store-info';
import { RegistrationStep } from 'entities/authorization/user';

type AnalyticsContext = {
  pushEvent: PushEvent;
};

const analyticsContext = createContext<AnalyticsContext>(null!);
const AnalyticsContextProvider = analyticsContext.Provider;
export const useAnalytics = () => useContext(analyticsContext);

type AnalyticsProviderProps = ComponentHasChildren & { config?: AnalyticsAdaptersConfig };

const EngagementTracker = () => {
  const { pushEvent } = useAnalytics();
  const engagementStartTime = useRef<Date | null>(null);

  useEffect(() => {
    const handler = () => {
      if (document.visibilityState === 'visible') {
        engagementStartTime.current = new Date();
      } else if (engagementStartTime) {
        const engagementEndTime = new Date();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const engagementDuration = (engagementEndTime - engagementStartTime.current) / 1000;
        pushEvent(AEventType.ENGAGEMENT_TIME, { duration: engagementDuration });
        engagementStartTime.current = null;
      }
    };

    document.addEventListener('visibilitychange', handler);
    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, [pushEvent]);

  return null;
};

const AnalyticsProvider = ({ children, config }: AnalyticsProviderProps) => {
  const session = useSession();
  const deviceType = useDevice();
  const deviceId = useDeviceId();
  const { user } = useCurrentUser();
  const query = useRouterQuery();
  const pathname = useRouterPathname();
  const storeInfo = useStoreInfo();

  const meta = useRef<Omit<AEventMeta, 'timestamp'>>(null!);

  const { platformOs } = usePlatformOs();

  meta.current = useMemo(
    () => ({
      session,
      device: { id: deviceId, type: deviceType },
      user: user ? { id: user.accountId } : undefined,
      route: {
        pathname,
        query,
      },
      store: pick(storeInfo, ['location', 'name', 'id']),
      platformOs,
    }),
    [session, deviceId, deviceType, user, pathname, query, storeInfo, platformOs],
  );

  const adapters = useRef<AnalyticsAdapter[]>([]);
  adapters.current = useAdapters(config);
  const pushEvent: PushEvent = useCallback((type, payload) => {
    const _meta = { ...meta.current, timestamp: new Date() };
    adapters.current.forEach((adapter) => adapter.pushEvent(type, payload, _meta));
  }, []);
  const isInited = useRef(false);
  if (!isInited.current && !isSSR) {
    pushEvent(AEventType.APP_INIT, undefined);
    isInited.current = true;
  }

  useEffect(() => {
    if (pathname !== Routes.Catalog) {
      pushEvent(AEventType.PAGE_VIEW, undefined);
    }
  }, [pushEvent, pathname, query]);

  const registrationStep = user?.registrationStepName;
  const prevRegistrationStep = usePrevious(registrationStep);
  useEffect(() => {
    if (!registrationStep || prevRegistrationStep === registrationStep) {
      return;
    }
    if (!prevRegistrationStep && registrationStep === RegistrationStep.COMPLETED) {
      pushEvent(AEventType.SIGN_IN, undefined);
      return;
    }
    if (prevRegistrationStep && !registrationStep) {
      pushEvent(AEventType.SIGN_OUT, undefined);
      return;
    }
    if (!prevRegistrationStep && registrationStep && registrationStep !== RegistrationStep.COMPLETED) {
      pushEvent(AEventType.SIGN_UP_START, undefined);
      return;
    }
    if (
      prevRegistrationStep &&
      prevRegistrationStep !== RegistrationStep.COMPLETED &&
      registrationStep === RegistrationStep.COMPLETED
    ) {
      pushEvent(AEventType.SIGN_UP_END, undefined);
    }
  }, [prevRegistrationStep, pushEvent, registrationStep]);

  const contextValue = useMemo(() => ({ pushEvent }), [pushEvent]);

  return (
    <AnalyticsContextProvider value={contextValue}>
      <EngagementTracker />
      {children}
    </AnalyticsContextProvider>
  );
};

export default AnalyticsProvider;
