export enum ApiErrorCode {
  UnknownError = 'UnknownError',
  Unauthorized = 401,
  NotFound = 'NotFound',
  BadRequest = 400,
}

type APIError<
  Code = ApiErrorCode,
  Details extends Record<string, any> | undefined = Record<string, any> | undefined,
> = {
  code: Code;
  subCode?: number;
  details?: Details;
  message: string;
};

type APIErrorExtended<
  Code = ApiErrorCode,
  Details extends Record<string, any> | undefined = Record<string, any> | undefined,
> = APIError<Code, Details> & {
  endpoint: string;
  traceId?: string;
};

type FetchConfig = Pick<RequestInit, 'method'> & {
  headers?: Record<string, string>;
  notifyWithSnackbar?: boolean;
  asFormData?: boolean;
  responseType?: 'text' | 'json';
};

export type { APIError, APIErrorExtended, FetchConfig };
