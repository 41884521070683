import { ReactNode, useCallback, useRef, KeyboardEvent } from 'react';

import { FocusTrap } from '@swe/shared/ui-kit/components/focus-trap';
import { isKeyPressed } from '@swe/shared/utils/keyboard';

type TabbableContentProps = {
  children: ReactNode;
  onLeave: () => void;
};

const TabbableContent = ({ children, onLeave }: TabbableContentProps) => {
  const items = useRef<HTMLLinkElement[]>([]);

  const setTabbableItems = useCallback((el: HTMLDivElement) => {
    items.current = el ? Array.from(el.querySelectorAll('a[href]')) : [];
  }, []);

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLDivElement>) => {
      const activeItem = items.current.find((item) => item === evt.target);
      if (!activeItem) return;

      if (isKeyPressed(evt, { key: 'Enter' })) {
        evt.stopPropagation();
        return;
      }

      if (isKeyPressed(evt, { key: 'Tab', mod: 'Shift' })) {
        if (activeItem === items.current[0]) onLeave();
        return;
      }

      if (isKeyPressed(evt, { key: 'Tab' })) {
        if (activeItem === items.current[items.current.length - 1]) onLeave();
      }
    },
    [onLeave],
  );

  return (
    <FocusTrap>
      <div
        ref={setTabbableItems}
        style={{ display: 'contents' }}
        onKeyDown={handleKeyDown}
      >
        {children}
      </div>
    </FocusTrap>
  );
};

export { TabbableContent };
