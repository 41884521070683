import { BoundedMutator } from '@swe/shared/network/transport/query/transport.types';
import Button from '@swe/shared/ui-kit/components/button';
import Divider from '@swe/shared/ui-kit/components/divider';
import Loader from '@swe/shared/ui-kit/components/loader';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { forwardRef } from 'react';

import styles from './styles.module.scss';

import { LaboratoryData as LaboratoryDataComponent } from 'domains/product/components/laboratory-data';
import { LaboratoryDataDisclaimer } from 'domains/product/components/laboratory-data/components/disclaimer';
import { ProductLaboratoryData } from 'entities/product/product';

type LaboratoryDataProps = ComponentHasClassName & {
  labData?: ProductLaboratoryData;
  isLoading?: boolean;
  error?: boolean;
  onRevalidate?: BoundedMutator<any>;
};

const LaboratoryData = forwardRef<HTMLDivElement, LaboratoryDataProps>(
  ({ className, labData, isLoading, onRevalidate, error }, ref) => {
    if (error)
      return (
        <div
          ref={ref}
          className={cx(className, styles.mockup)}
        >
          <Text
            className={styles.error}
            variant="control"
            size="lg"
          >
            We couldn&apos;t load laboratory data. Try reload data again.
          </Text>
          <Button
            ariaLabel="Reload laboratory data"
            size="sm"
            color="light"
            onClick={onRevalidate}
          >
            Reload
          </Button>
        </div>
      );

    if (!labData) return null;

    return (
      <div
        className={className}
        ref={ref}
      >
        {isLoading ? (
          <div className={styles.mockup}>
            <Loader size="xl" />
          </div>
        ) : (
          <Stack>
            <LaboratoryDataComponent data={labData} />
            <Divider />
            <LaboratoryDataDisclaimer />
          </Stack>
        )}
      </div>
    );
  },
);

export type { LaboratoryDataProps };
export { LaboratoryData };
