import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';
import { PaginatedResponse, Pagination } from '@swe/shared/types/wrappers/pagination';

import { PlatformOs } from 'common/use-cases/use-platform-os';

import { ProductFilters, ProductFiltersQuery } from 'entities/product/filter';
import { Product } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

type SearchFilter = {
  uiLabel: string;
  value: string;
  filter: Record<string, string>;
};

type SortingMethod = NamedEntity<number> & { selected: boolean };

type GetListResponse = PaginatedResponse<Product> & {
  filters: ProductFilters;
  searchFilters: SearchFilter[];
  sortingMethods: SortingMethod[];
};

type Params = {
  saleType?: StoreSaleType;
  searchTerm?: string;
  sortingMethodId?: number;
  filters?: ProductFiltersQuery;
  isInStockOnly?: boolean;
  platformOs: PlatformOs;
} & Pagination;

const NAME = '/Products/GetProductList';
const GetListEndpoint = createModernEndpoint<Params, GetListResponse>(NAME);

export type { GetListResponse, Params, SortingMethod };
export { GetListEndpoint };
export default GetListEndpoint;
