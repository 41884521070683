import { jsonParseSafe } from '@swe/shared/utils/object';

import { Routes } from 'common/router/constants';
import GetShopConfig from 'endpoints/shop/get-shop-config';
import { Policy, ShopConfigDTO } from 'entities/shop/config';

enum PolicyKey {
  TermsOfService,
  PrivacyPolicy,
}

const usePoliciesList = (): { policies: Policy[]; registration: Policy[]; isLoading: boolean } => {
  const { data, isLoading } = GetShopConfig.useRequest({});
  const { policies: outerPolicies, privacyPolicy, termsOfService } = data ?? ({} as ShopConfigDTO);
  const outerPoliciesParsed = jsonParseSafe<Policy[]>(outerPolicies) ?? [];

  const policies: Policy[] =
    outerPoliciesParsed.length > 0
      ? outerPoliciesParsed
      : ([
          privacyPolicy && {
            title: 'Privacy Policy',
            href: Routes.AgreementsPrivacyPolicy,
            showAtRegistration: true,
            content: privacyPolicy,
            key: PolicyKey.PrivacyPolicy,
          },
          termsOfService && {
            title: 'Terms of Service',
            href: Routes.AgreementsTermsOfService,
            showAtRegistration: true,
            content: termsOfService,
            key: PolicyKey.TermsOfService,
          },
        ].filter((val) => !!val) as Policy[]);

  return {
    registration: policies.filter(({ showAtRegistration }) => showAtRegistration),
    policies,
    isLoading,
  };
};

const usePolicy = (key: PolicyKey) => {
  const { policies, isLoading } = usePoliciesList();
  const policy = policies.find((policy) => policy.key === key);
  return { policy, isLoading };
};

export { usePolicy, usePoliciesList, PolicyKey };
