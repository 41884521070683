import { Serializable } from '@swe/shared/types/json';
import { Pagination } from '@swe/shared/types/wrappers/pagination';
import { isEqual, jsonParseSafe, merge, omitBy } from '@swe/shared/utils/object';
import { isEmpty } from '@swe/shared/utils/other';

type CatalogFiltersValues = Record<string, any>;
type CatalogSortingMethodId = EntityID<number>;

const DEFAULT_CATALOG_FILTERS = {};
const DEFAULT_CATALOG_SORTING_METHOD: CatalogSortingMethodId = 7;

const DEFAULT_PAGINATION: Pagination = {
  page: 1,
  pageSize: 24,
};

const encodeCatalogFilters = <T extends Serializable>(
  filters: Partial<T> | null | undefined,
  defaultFilters: Partial<T>,
) => {
  if (!filters) {
    return undefined;
  }
  const clearedFromDefaults = omitBy(filters, (value, key: keyof Partial<T>) => isEqual(value, defaultFilters[key]));
  if (isEmpty(clearedFromDefaults)) {
    return undefined;
  }
  return JSON.stringify(clearedFromDefaults);
};

const decodeCatalogFilters = <T extends Serializable>(filters: string | undefined, defaultFilters: Partial<T>): T => {
  const parsedFilters = jsonParseSafe(filters);
  return (parsedFilters ? merge({}, defaultFilters, parsedFilters) : defaultFilters) as T;
};

export {
  DEFAULT_CATALOG_FILTERS,
  DEFAULT_PAGINATION,
  DEFAULT_CATALOG_SORTING_METHOD,
  encodeCatalogFilters,
  decodeCatalogFilters,
};
export type { CatalogFiltersValues, CatalogSortingMethodId };
