import Button from '@swe/shared/ui-kit/components/button';
import { TrashIcon } from '@swe/shared/ui-kit/components/icon';
import { Stack } from '@swe/shared/ui-kit/components/stack';
import Tag from '@swe/shared/ui-kit/components/tag';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { getTokenValue } from '@swe/shared/ui-kit/theme/provider/helpers';

import cx from 'clsx';

import { SyntheticEvent } from 'react';

import styles from './styles.module.scss';

import { ProductBadges } from 'common/components/product-card/components/badges';
import { ProductImage } from 'common/components/product-card/components/image';
import { Potency } from 'common/components/product-card/components/potency';
import { ProductPrice } from 'common/components/product-card/components/price';
import { ProductTier } from 'common/components/product-card/components/product-tier';
import { PromoBadge, PromoName } from 'common/components/product-card/components/promo';
import { ProductTitle } from 'common/components/product-card/components/title';
import { Wrapper } from 'common/components/product-card/components/wrapper';
import { CommonCardProps } from 'common/components/product-card/types';
import { prepareProductForCard, useBadges } from 'common/use-cases/product-card';
import { getProductImageAlt, getProductImageTitle } from 'entities/common/seo';
import { getProductVariantNextTier, getProductVariantPrice } from 'entities/product/product';

type ProductCardProps = CommonCardProps & {
  action?: false;
  onRemoveAll?: () => void;
};

const ProductCard = ({
  className,
  externalId,
  product,
  variant: outerVariant,
  action,
  qtyInCart,
  addToCart,
  unavailable,
  onRemoveAll,
  lazy,
  open,
}: ProductCardProps) => {
  const { variant, images, labTests, productName, promos, hasPriceFrom } = prepareProductForCard(product, outerVariant);
  const [price, oldPrice] = getProductVariantPrice(variant, qtyInCart);
  const nextTier = getProductVariantNextTier(variant, qtyInCart);
  const badges = useBadges(product, variant, false);
  const theme = useTheme();

  const onRemoveAllHandler = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onRemoveAll?.();
  };

  return (
    <Wrapper
      open={open}
      className={cx(styles.root, className)}
      product={product}
      variant={variant}
      unavailable={unavailable}
      externalId={externalId}
    >
      <ProductImage
        className={styles.image}
        images={images}
        alt={getProductImageAlt(product)}
        title={getProductImageTitle(product)}
        width={getTokenValue(theme.productCard.horizontal.md.imageSize)}
        lazy={lazy}
      />
      <div className={styles.details}>
        <div className={styles.info}>
          <div className={styles.badgeContainer}>
            {theme.productCard.badge.promo.isVisible && <PromoBadge promos={promos} />}
            <ProductBadges badges={badges} />
          </div>
          <ProductTitle
            name={productName}
            brand={product.brand?.name}
            category={product.category?.name}
          />
          <div className={styles.properties}>
            <Tag
              name={variant.name}
              size="sm"
            >
              {variant.name}
              {!variant.isPacked && ' · by weight'}
            </Tag>
            <Potency potency={labTests} />
          </div>
        </div>
        {(nextTier || promos.length > 0) && (
          <div className={styles.amount}>
            {nextTier && <ProductTier tier={nextTier} />}
            <PromoName promos={promos} />
          </div>
        )}
        {!unavailable && (!!price || action !== false) && (
          <div className={styles.priceBlock}>
            <ProductPrice
              from={hasPriceFrom}
              price={price}
              oldPrice={oldPrice}
            />
            {action !== false ? (
              <Stack
                className={styles.addToCartWrapper}
                direction="row"
              >
                {onRemoveAll && (
                  <Button
                    color="light"
                    size="sm"
                    ariaLabel="remove all"
                    icon={TrashIcon}
                    onClick={onRemoveAllHandler}
                  />
                )}
                {addToCart && <div className={styles.addToCart}>{addToCart}</div>}
              </Stack>
            ) : null}
          </div>
        )}
        {(!!unavailable || (action === false && qtyInCart)) && (
          <div className={styles.unvailableBlock}>
            {unavailable && <span>{unavailable}</span>}
            {(action === false || unavailable) && <span className={styles.pcs}>{qtyInCart} pcs</span>}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export type { ProductCardProps };
export { ProductCard };
