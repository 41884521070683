import Link from '@swe/shared/providers/router/link';
import { Trans } from '@swe/shared/tools/intl/components/trans';
import RowSpacer from '@swe/shared/ui-kit/components/form/row-spacer';

import { Text, TextProps } from '@swe/shared/ui-kit/components/text';

import { usePoliciesList } from 'common/use-cases/policies';
import { Policy } from 'entities/shop/config';

type AgreementsLinksProps = {
  spacer?: boolean;
  policies: Policy[];
};

const PolicyLinks = ({ spacer, policies }: AgreementsLinksProps) => {
  return (
    <>
      <span>
        {policies.map(({ title, href }, index) => (
          <span key={String(href)}>
            <Link href={href}>{title}</Link>
            {index < policies.length - 1 && policies.length > 2 && ', '}
            {(index === policies.length - 2 || (policies.length === 2 && index !== policies.length - 1)) && ' and '}
          </span>
        ))}
      </span>
      {spacer && <RowSpacer />}
    </>
  );
};

type AgreementDisclaimerProps = {
  spacer?: boolean;
  type?: 'signIn' | 'signUp' | 'guestSignUp' | 'easySignUp';
} & TextProps<'div'>;

const AgreementDisclaimer = ({ type = 'signIn', spacer = true, ...textProps }: AgreementDisclaimerProps) => {
  const { registration } = usePoliciesList();

  if (registration.length === 0) {
    return null;
  }

  return (
    <Text {...textProps}>
      <Trans
        id={`common.agreementDisclaimer.${type}`}
        values={{
          0: (
            <PolicyLinks
              policies={registration}
              spacer={spacer}
            />
          ),
        }}
        defaultMessage="By continuing, you agree to our <0/>"
      />
    </Text>
  );
};

export default AgreementDisclaimer;
