import useBrowserEventQueue from '@swe/shared/hooks/use-browser-event-queue';
import { isSSR } from '@swe/shared/utils/environment';

import { useMemo } from 'react';

import { AEventPayloadByType, AEventType, AnalyticsAdapter, Session } from 'common/providers/analytics/constants';

import { useRouterNavigate, useRouterPathname, useRouterQuery } from 'common/router';
import AddAnalyticsEventEndpoint from 'endpoints/web-analytics/add-event';
import { AnalyticsEvent } from 'entities/analytics/event';

const TO_SWEED_EVENT_TYPE: Partial<Record<AEventType, string>> = {
  [AEventType.APP_INIT]: 'page.view',
  [AEventType.SIGN_IN]: 'sign.in',
  [AEventType.SIGN_OUT]: 'sign.out',
  [AEventType.SIGN_UP_START]: 'sign.up.start',
  [AEventType.SIGN_UP_END]: 'sign.up.end',
  [AEventType.PRODUCT_DETAILS_VIEW]: 'product_card_opened',
  [AEventType.CART_CHANGE]: 'fill.order',
  [AEventType.CART_CHECKOUT]: 'checkout.order',
  [AEventType.ORDER_PLACE]: 'place.order',
  [AEventType.ORDER_FIRST]: 'order_first',
  [AEventType.BANNER_CLICK]: 'banner_click',
};

const TO_SWEED_EVENT_PAYLOAD = {
  [AEventType.PRODUCT_DETAILS_VIEW]: (payload: AEventPayloadByType[AEventType.PRODUCT_DETAILS_VIEW]) => payload,
  [AEventType.CART_CHANGE]: (payload: AEventPayloadByType[AEventType.CART_CHANGE]) => payload,
  [AEventType.ORDER_PLACE]: (payload: AEventPayloadByType[AEventType.ORDER_PLACE]) => ({
    order: { invoice: { id: payload.order?.invoiceId ?? 0 } },
  }),
  [AEventType.ORDER_FIRST]: (id: number) => ({ order: { invoice: { id } } }),
  [AEventType.BANNER_CLICK]: (payload: AEventPayloadByType[AEventType.ORDER_PLACE]) => payload,
};

const SID_KEY = '__sw-id';
const SW_A_PAYLOAD_KEY = '__sw-a-payload';
const SID_LIVE_TIME = 72 * 60 * 60 * 1000;

const getSweedSID = (): [EntityID, Record<string, string>] | [undefined, undefined] => {
  const sidStored = localStorage.getItem(SID_KEY);
  if (!sidStored) {
    return [undefined, undefined];
  }
  try {
    const sidParsed: Session = JSON.parse(sidStored);
    const createdAt = new Date(sidParsed.createdAt);
    if (Date.now() - createdAt.valueOf() <= SID_LIVE_TIME) {
      let swAPayload: Record<string, string> = {};
      try {
        swAPayload = JSON.parse(localStorage.getItem(SW_A_PAYLOAD_KEY) ?? '{}');
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return [sidParsed.id, swAPayload];
    }
    return [undefined, undefined];
  } catch (e) {
    return [undefined, undefined];
  }
};

const useSweedSID = () => {
  const query = useRouterQuery();
  const pathname = useRouterPathname();
  const navigate = useRouterNavigate();
  if (!isSSR && query.sweed_sid) {
    const { sweed_sid, ...restQuery } = query;
    const swAPayloadKeys = Object.keys(restQuery).filter((key) => /^sweed_.+/.test(key));
    const swAPayload: Record<string, string> = {};
    swAPayloadKeys.forEach((key) => {
      swAPayload[key] = restQuery[key] as string;
      delete restQuery[key];
    });
    localStorage.setItem(SID_KEY, JSON.stringify({ id: sweed_sid, createdAt: new Date().toISOString() }));
    localStorage.setItem(SW_A_PAYLOAD_KEY, JSON.stringify(swAPayload));
    void navigate({ pathname, query: restQuery }, { replace: true });
  }
};

const useSweedAAdapter = (): AnalyticsAdapter => {
  useSweedSID();
  const { pushEvent: _pushEvent } = useBrowserEventQueue<AnalyticsEvent>(async (events) => {
    // console.log(events);
    return AddAnalyticsEventEndpoint.request({ events });
  });

  return useMemo(
    () => ({
      pushEvent: (type, payload, { session, device, timestamp, platformOs }) => {
        const eventName = TO_SWEED_EVENT_TYPE[type];
        if (!eventName) {
          return;
        }
        const [swSid, swAPayload] = getSweedSID();
        const eventPayload = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...(TO_SWEED_EVENT_PAYLOAD[type]?.(payload) ?? {}),
          sweedSid: swSid,
          ...(swAPayload ?? {}),
        };
        const richEvent: AnalyticsEvent = {
          appName: 'Shop',
          platform: 'NewShop',
          eventType: 'Ui',
          sessionId: session.id,
          sessionStarted: session.createdAt,
          eventCreated: timestamp.toISOString(),
          deviceType: (device.type.charAt(0).toUpperCase() + device.type.slice(1)) as AnalyticsEvent['deviceType'],
          deviceId: device.id,
          eventName,
          eventPayload: JSON.stringify(eventPayload),
          platformOs,
        };
        return _pushEvent(richEvent);
      },
    }),
    [_pushEvent],
  );
};

export default useSweedAAdapter;
