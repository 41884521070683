import cx from 'clsx';

import { ReactNode, useCallback } from 'react';

import AnimatedHeight from '@swe/shared/ui-kit/components/animated-height';
import { ChevronDownIcon, ChevronUpIcon } from '@swe/shared/ui-kit/components/icon';

import { HeadingLevel, SectionHeading, SectionHeadingProps } from '@swe/shared/ui-kit/components/section-heading';
import useSection from '@swe/shared/ui-kit/components/section/hooks';

import styles from './styles.module.scss';

type BaseSectionProps = {
  heading?: Omit<SectionHeadingProps, 'onClick' | 'trailIcon' | 'tabIndex'>;
  children: ReactNode;
  className?: string;
  noTopPadding?: boolean;
  disabled?: boolean;
  headingLevel?: HeadingLevel;
};

type StaticSectionProps = BaseSectionProps & {
  expandable?: false;
  expanded?: never;
  onToggle?: never;
  defaultExpanded?: never;
};

type ExpandableSectionProps = BaseSectionProps & {
  expandable: true;
  expanded?: boolean;
  defaultExpanded?: boolean;
  onToggle?: (expanded: boolean) => void;
};

type SectionProps = StaticSectionProps | ExpandableSectionProps;

const Section = ({
  className,
  heading,
  children,
  expandable,
  expanded,
  defaultExpanded,
  onToggle,
  disabled = false,
  noTopPadding,
  headingLevel,
}: SectionProps) => {
  const { isExpanded, handleToggle } = useSection({
    defaultExpanded,
    expandable,
    expanded,
    onToggle,
  });
  const handleSectionToggle = useCallback(() => {
    if (!disabled) {
      handleToggle();
    }
  }, [disabled, handleToggle]);

  const hasHeader = !!(expandable || heading?.children);
  const trailIcon = expandable ? (isExpanded ? ChevronUpIcon : ChevronDownIcon) : undefined;

  return (
    <div
      className={cx(
        disabled && styles._disabled,
        noTopPadding && styles._noTopPadding,
        hasHeader && styles._hasHeader,
        className,
      )}
    >
      {hasHeader && (
        <SectionHeading
          {...heading}
          trailIcon={trailIcon}
          onClick={expandable ? handleSectionToggle : undefined}
          level={headingLevel}
        >
          {heading?.children}
        </SectionHeading>
      )}
      <AnimatedHeight
        expanded={isExpanded}
        transition={hasHeader}
      >
        <div className={styles.content}>{children}</div>
      </AnimatedHeight>
    </div>
  );
};

export type { SectionProps, ExpandableSectionProps };
export { Section };
export default Section;
