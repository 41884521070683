import SupHeaderComponent from 'common/containers/header/components/sup-header';
import { useStoreConfig } from 'common/providers/config';
import useMedChat from 'common/use-cases/use-med-chat';
import { useStoreInfo } from 'common/use-cases/use-store-info';

const SupHeader = () => {
  const {
    contacts: { phone },
  } = useStoreInfo();
  const { externalNav } = useStoreConfig();
  const { open } = useMedChat();

  return (
    <SupHeaderComponent
      tel={phone}
      nav={externalNav}
      onChatClick={open}
    />
  );
};

export default SupHeader;
