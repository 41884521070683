import { useCallback, useEffect, useState } from 'react';

import { useQuantityStorage } from 'common/providers/cart/use-cases/use-quantity-storage';

const useQuantity = (productId: ProductId, variantId: VariantId) => {
  const { cartEvents, getQuantity } = useQuantityStorage();
  const [quantity, setQuantity] = useState<number>(getQuantity(productId, variantId));

  useEffect(() => {
    const unsub = cartEvents.on('onQuantityChange', (e) => {
      if (e.productId === productId && e.variantId === variantId) {
        setQuantity(e.qty);
      }
    });

    return () => {
      unsub();
    };
  }, [cartEvents, productId, variantId]);

  useEffect(() => {
    setQuantity(getQuantity(productId, variantId));
  }, [getQuantity, productId, variantId]);

  return {
    quantity,
    getQuantity: useCallback(() => quantity, [quantity]),
  };
};

export { useQuantity };
