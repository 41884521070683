import { useCallback, useMemo } from 'react';

import { Delivery } from 'common/entities/map/Delivery';
import { useStoreConfig } from 'common/providers/config';
import { useStoreInfo } from 'common/use-cases/use-store-info';
import GetShopInfoList from 'endpoints/shop/get-shop-info-list';
import { LocationCoords, locationsDistance, ShopFulfillmentType, ShopInfo } from 'entities/shop/info';

type UseStoresArg = {
  location?: LocationCoords;
};

const filterStoresByFulfillment = (stores: ShopInfo[], fulfillmentType: ShopFulfillmentType) =>
  stores.filter((store) => store.fulfillmentTypes.includes(fulfillmentType));

const useStoreList = () => {
  const { data: stores = [], isLoading } = GetShopInfoList.useRequest(undefined);

  return {
    stores: stores || [],
    isLoading,
  };
};

const calculateMileageAndSort = (stores: ShopInfo[], location?: LocationCoords) => {
  if (!location) return [];

  return stores
    .map((store) => {
      const { coords } = store.location;
      return {
        ...store,
        milesFromYou: locationsDistance(location, coords),
      };
    })
    .sort((s1, s2) => s1.milesFromYou - s2.milesFromYou);
};

const useStores = () => {
  const { stores, isLoading } = useStoreList();

  const getById = useCallback((id?: EntityID) => stores.find((s) => s.id === id), [stores]);

  return {
    stores,
    delivery: useMemo(() => new Delivery(stores), [stores]),
    isLoading,
    getById,
  };
};

const useStoresFiltered = ({ location }: UseStoresArg = {}) => {
  const { id } = useStoreConfig();
  const currentStore = useStoreInfo();
  const { stores, isLoading, getById } = useStores();

  const storesWithDeliveryOnly = useMemo(
    () => filterStoresByFulfillment(stores, ShopFulfillmentType.Delivery),
    [stores],
  );

  const storesWithPickupOnly = useMemo(() => filterStoresByFulfillment(stores, ShopFulfillmentType.Pickup), [stores]);

  const storesWithMileage = useMemo(
    () => calculateMileageAndSort(storesWithPickupOnly, location),
    [location, storesWithPickupOnly],
  );

  const nearestStores = useMemo(
    () => storesWithMileage.filter((s) => s.id !== id).slice(0, 3),
    [id, storesWithMileage],
  );

  const filteredStores = useMemo(
    () => ({
      all: stores,
      withDelivery: storesWithDeliveryOnly,
      withPickup: storesWithPickupOnly,
      withMileage: storesWithMileage,
      nearest: nearestStores,
      current: currentStore,
    }),
    [stores, storesWithDeliveryOnly, storesWithPickupOnly, storesWithMileage, nearestStores, currentStore],
  );

  return {
    stores: filteredStores,
    getById,
    isLoading,
  };
};

export { useStoreList, useStores, useStoresFiltered };
