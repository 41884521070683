import { useMounted } from '@swe/shared/hooks/use-mounted';
import SharedProvider from '@swe/shared/provider';
import { GoogleApiProvider } from '@swe/shared/providers/google-api';
import { PersistStateStorageProvider } from '@swe/shared/providers/persist-state';
import { CommonQueryParams } from '@swe/shared/providers/router/constants';
import SnackbarProvider from '@swe/shared/providers/snackbar';
import { useIsPWA } from '@swe/shared/tools/is-pwa';
import { Error500 } from '@swe/shared/ui-kit/components/error-500';
import { ErrorBoundary } from '@swe/shared/ui-kit/components/error-boundary';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { isSSR } from '@swe/shared/utils/environment';

import posthog from 'posthog-js';

import { PostHogProvider } from 'posthog-js/react';

import { useMemo } from 'react';

import { Outlet, RouteObject, ScrollRestoration, useParams } from 'react-router-dom';

import { useAppSettings } from 'app/app-settings';

import { firebase } from 'app/firebase';
import { useClientWidth } from 'app/hooks';
import Document from 'app/layout/document';
import { MainLayout } from 'app/layout/main';
import { getThemeUtils } from 'app/theme';
import { parseUA } from 'app/utils';
import logger from 'app/utils/logger';
import { usePageLayoutProps } from 'app/utils/page-meta';
import { ActionRequiredNotifier } from 'common/containers/action-required-notifier';
import AgeRestriction from 'common/containers/age-restriction';
import Footer from 'common/containers/footer';
import Header from 'common/containers/header';
import SubHeader from 'common/containers/header/containers/sub-header';
import SupHeader from 'common/containers/header/containers/sup-header';
import { MedicalCardAssistance } from 'common/containers/medical-card-assistance';
import { ProductDetailsModal } from 'common/containers/product-details-modal';
import { SignUpNotifier } from 'common/containers/sign-up-notifier';
import { SignupIncentivization } from 'common/containers/signup-incentivization';
import AnalyticsProvider from 'common/providers/analytics';
import { AnalyticsAdaptersConfig } from 'common/providers/analytics/adapters';
import { CartProvider } from 'common/providers/cart';
import StoreConfigProvider from 'common/providers/config';
import { GuestProvider } from 'common/providers/guest';
import { ChatMessageNotifier } from 'common/providers/message';
import PickupDeliveryProvider from 'common/providers/pickup-delivery';
import { PWAProvider } from 'common/providers/pwa';
import { useInitStorages } from 'common/providers/storages';
import UserProvider from 'common/providers/user';
import RouterProvider from 'common/router';
import { PDPRouterProvider } from 'common/use-cases/use-pdp';

import { SignUpProvider } from 'domains/authorization/containers/sign-up/use-cases/use-sign-up';
import { ReferralProgramSubscriber } from 'domains/profile/containers/referral-program/containers/referral-program-subscriber';

import '@swe/shared/ui-kit/theme/provider/global.css';

const ErrorFallback = ({ error }: { error: any }) => <Error500 error={error} />;

type AppLayoutProps = ComponentHasChildren & { pages: RouteObject[] };

const AppLayout = ({ children, pages }: AppLayoutProps) => {
  const appSettings = useAppSettings();
  const {
    storagesFallback,
    reqUserAgent,
    clientWidthFallback,
    themeBuild,
    storeConfig,
    reqHasAuthCookie,
    runtimeConfig,
  } = appSettings;
  const { layoutProps } = usePageLayoutProps();

  const revalidateOnInit = reqHasAuthCookie ?? true;
  const themeUtils = getThemeUtils(themeBuild);
  const storages = useInitStorages(storagesFallback);
  const { isOpera, isIOS, isAndroid } = parseUA(isSSR ? reqUserAgent : window.navigator.userAgent);
  const clientWidth = useClientWidth(clientWidthFallback);
  const isPWA = useIsPWA();

  const analyticsConfig: AnalyticsAdaptersConfig = useMemo(
    () => ({ posthog: runtimeConfig.posthogConfig }),
    [runtimeConfig.posthogConfig],
  );

  useMounted(() => {
    void firebase.init();
  });

  const params = useParams();
  const saleTypeQueryParam = params[CommonQueryParams.SaleType];
  const defaultQuery = useMemo(() => {
    return saleTypeQueryParam ? { [CommonQueryParams.SaleType]: saleTypeQueryParam } : {};
  }, [saleTypeQueryParam]);

  return (
    <RouterProvider
      routes={pages}
      defaultQuery={defaultQuery}
    >
      <Document appSettings={appSettings}>
        <StoreConfigProvider storeConfig={storeConfig}>
          <PostHogProvider client={posthog}>
            <SharedProvider
              {...themeUtils}
              errorFallback={ErrorFallback}
              onError={logger.reactError}
              revalidateOnInit={revalidateOnInit}
              externalApiBasePath={storeConfig.externalApiBasePath}
              externalApiHost={storeConfig.externalApiHost}
              clientWidth={clientWidth}
              isIOS={isIOS}
              isAndroid={isAndroid}
              isPWA={isPWA}
              messages={storeConfig.translations}
            >
              <GoogleApiProvider apiKey={storeConfig.googleMapsApiKey}>
                <PersistStateStorageProvider storages={storages}>
                  <SnackbarProvider>
                    <PDPRouterProvider>
                      <GuestProvider>
                        <UserProvider hasAuthCookie={reqHasAuthCookie}>
                          <AnalyticsProvider config={analyticsConfig}>
                            <PWAProvider isOpera={isOpera}>
                              <CartProvider>
                                <SignUpProvider>
                                  <PickupDeliveryProvider>
                                    <ProductDetailsModal />
                                    <ReferralProgramSubscriber />
                                    <ChatMessageNotifier />
                                    <MainLayout
                                      preHeader={
                                        <>
                                          <SignUpNotifier />
                                          <ActionRequiredNotifier />
                                          <SupHeader />
                                        </>
                                      }
                                      header={<Header />}
                                      subheader={<SubHeader />}
                                      footer={<Footer />}
                                      preFooter={<MedicalCardAssistance />}
                                      {...layoutProps}
                                    >
                                      <ErrorBoundary level="fatal">{children ?? <Outlet />}</ErrorBoundary>
                                    </MainLayout>
                                    <AgeRestriction />
                                    <SignupIncentivization />
                                  </PickupDeliveryProvider>
                                </SignUpProvider>
                              </CartProvider>
                            </PWAProvider>
                          </AnalyticsProvider>
                        </UserProvider>
                      </GuestProvider>
                    </PDPRouterProvider>
                  </SnackbarProvider>
                </PersistStateStorageProvider>
              </GoogleApiProvider>
            </SharedProvider>
          </PostHogProvider>
        </StoreConfigProvider>
        <ScrollRestoration />
      </Document>
    </RouterProvider>
  );
};

export default AppLayout;
