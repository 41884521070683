import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ProductLaboratoryData, ProductVariant } from 'entities/product/product';

type Data = ProductLaboratoryData;
type Params = {
  variantId: ProductVariant['id'];
};

const NAME = '/Products/GetExtendedLabdata';
const GetLabDataEndpoint = createModernEndpoint<Params, Data>(NAME, undefined, {
  dedupingInterval: 10 * 60 * 1000,
});

export { NAME };
export default GetLabDataEndpoint;
export { GetLabDataEndpoint };
