import { useIntl } from '@swe/shared/tools/intl';

import { memo, useCallback, useMemo } from 'react';

import { ProductCard as DumbProductCard } from 'common/components/product-card';
import { ProductCardProps as HorizontalProductCardProps } from 'common/components/product-card/horizontal';
import { GetQuantityFunc, Strategy } from 'common/components/product-card/types';
import { ProductCardProps as VerticalProductCardProps } from 'common/components/product-card/vertical';
import { AddToCartContainer } from 'common/containers/add-to-cart';
import { DisableOrdering } from 'common/containers/disable-ordering';
import { useQuantities } from 'common/providers/cart/use-cases/use-quantities';
import { useStoreConfig } from 'common/providers/config';
import { usePDPControls } from 'common/use-cases/use-pdp';
import { getProductVariant, Product, ProductVariant } from 'entities/product/product';

type ProductCardConnectedProps = {
  getQuantity?: GetQuantityFunc;
  index: number;
  analyticalItemListId: string | number;
  analyticalItemListName: string;
  hidePrice?: boolean; // hidden from view, but not from analytics.
} & (
  | ({ layout: 'vertical' } & Omit<VerticalProductCardProps, 'qtyInCart' | 'open'>)
  | ({ layout: 'horizontal' } & Omit<HorizontalProductCardProps, 'qtyInCart' | 'open'>)
);

const ProductCardConnected = memo((props: ProductCardConnectedProps) => {
  const { intl } = useIntl();
  const variant = getProductVariant(props.product, props.variant);
  const { getQuantity } = useQuantities(props.product.id);
  const { open } = usePDPControls();
  const finalGetQty = props.getQuantity ?? getQuantity;
  const qtyInCart = finalGetQty(variant.id);
  const manyPromosTitle = intl.t('common.productCard.manyPromosTitle');
  const { productCardBadgePlacement } = useStoreConfig();

  const analyticsData = useMemo(
    () => ({
      originId: props.analyticalItemListId,
      originName: props.analyticalItemListName,
      idx: props.index,
    }),
    [props.analyticalItemListId, props.analyticalItemListName, props.index],
  );

  const openHandler = useCallback(
    (product: Product, variant: ProductVariant, strategy?: Strategy) => {
      open({
        product,
        variant,
        strategy,
        analyticsData,
      });
    },
    [analyticsData, open],
  );

  const product = useMemo(() => {
    return {
      ...props.product,
      variants: props.product.variants.map((variant) => ({
        ...variant,
        ...(props.hidePrice
          ? {
              price: undefined,
            }
          : {}),
      })),
    };
  }, [props.hidePrice, props.product]);

  return (
    <DumbProductCard
      {...props}
      product={product}
      open={openHandler}
      qtyInCart={qtyInCart}
      badgePlacement={productCardBadgePlacement}
      addToCart={
        <DisableOrdering>
          <AddToCartContainer
            product={props.product}
            color={props.layout === 'horizontal' ? 'light' : undefined}
            variant={props.variant}
            lastItemIconTrash={props.layout !== 'horizontal'}
            lockLastItem={props.layout === 'horizontal'}
            getQuantity={finalGetQty}
            cartMeta={analyticsData}
          />
        </DisableOrdering>
      }
      manyPromosTitle={props.manyPromosTitle || manyPromosTitle}
    />
  );
});

export type { ProductCardConnectedProps };
export { ProductCardConnected };
