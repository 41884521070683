import GetCartEndpoint from 'endpoints/cart/get-cart';
import { Cart } from 'entities/cart/cart';

type AddToQueue = (f: () => Promise<Cart>) => Promise<Cart>;

const useGetCartRequest = (addToQueue: AddToQueue) => {
  const {
    data: cart,
    isLoading,
    isValidating,
    mutate,
  } = GetCartEndpoint.useRequest(undefined, undefined, {
    use: addToQueue,
    keepPreviousData: true,
    dedupingInterval: 1000,
  });

  return {
    cart,
    isLoading,
    isValidating,
    mutate,
  };
};

export { useGetCartRequest };
