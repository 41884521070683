import { Chat, ChatId, ChatType, Order, OrderId, OrderProcessStatus, OrderStatus } from 'entities/common/orders';

enum ChatMessageStatus {
  Undefined,
  Created,
  Received,
  SendFailed,
  Read,
}

type ChatUser = {
  id: string;
  name: string;
  externalId: string;
  isEmployee: boolean;
  isClient: boolean;
  isCourier: boolean;
  isSystemUser: boolean;
};

type ChatMessage = {
  messageId: string;
  chatId: string;
  text: string;
  status: ChatMessageStatus;
  sender: ChatUser;
  date: string;
  clientMessageId: string;
  readers: ChatUser[];
  readerIds: string[];
  orderId: OrderId;
  orderNumber: number;
};

enum ChatMessageReader {
  Client = 'isClient',
  Courier = 'isCourier',
  Employee = 'isEmployee',
  SystemUser = 'isSystemUser',
}

type CourierLocation = {
  coordinates: LatLng | null;
  eta: DateISOString | null;
  isOnTheWay: boolean;
};

const CHAT_CLOSED_TEXT_MESSAGE = 'Chat is closed';
const isSystem = (chatUser: ChatUser) =>
  !(chatUser.isEmployee && chatUser.isCourier && chatUser.isClient && chatUser.isSystemUser);

/**
 * TODO: Remove chatType argument because it is an absolute trash.
 * At the moment, BE can't be fixed, so we fix it on the FE
 */
const getMessageSenderType = (message: ChatMessage, chatType?: ChatType) => {
  if (message.sender.isClient) return 'Client';
  if (chatType === ChatType.Driver || message.sender.isCourier) return 'Driver';
  if (chatType === ChatType.Store || message.sender.isSystemUser || message.sender.isEmployee) return 'Manager';

  return 'Interlocutor';
};
const isMessageSent = (message: ChatMessage) =>
  [ChatMessageStatus.Created, ChatMessageStatus.Received, ChatMessageStatus.Read].includes(message.status);
const isMessageReadBy = (message: ChatMessage, by: ChatMessageReader) => message.readers?.some((reader) => reader[by]);
const isMessageReadByAny = (message: ChatMessage, readBy: ChatMessageReader[]) =>
  message.readers?.some((reader) => readBy.some((maybeReader) => reader[maybeReader]));

const getOrderStatusLabel = (status: OrderStatus) =>
  ({
    [OrderStatus.Cancelled]: 'Cancelled',
    [OrderStatus.Closed]: 'Closed',
    [OrderStatus.Completed]: 'Completed',
    [OrderStatus.OrderConfirmed]: 'Confirmed',
    [OrderStatus.NewOrder]: 'Confirmation',
    [OrderStatus.OrderPlaced]: 'Confirmation',
    [OrderStatus.DroppedByUser]: 'Canceled',
  })[status];

const isDone = (status: OrderProcessStatus) => {
  return [
    OrderProcessStatus.Delivered,
    OrderProcessStatus.PickedUp,
    OrderProcessStatus.Cancelled,
    OrderProcessStatus.CancelledByTimeout,
  ].includes(status);
};

const isSuccessDone = (status: OrderProcessStatus) => {
  return [OrderProcessStatus.Delivered, OrderProcessStatus.PickedUp].includes(status);
};

const isCanceled = (status: OrderProcessStatus) => {
  return [OrderProcessStatus.Cancelled, OrderProcessStatus.CancelledByTimeout].includes(status);
};

const isOutForDelivery = (status: OrderProcessStatus) => {
  return [OrderProcessStatus.OutForDelivery].includes(status);
};

const isPacking = (status: OrderProcessStatus) => {
  return [OrderProcessStatus.Packing].includes(status);
};

const isDelivering = (status: OrderProcessStatus) => {
  return [OrderProcessStatus.OnTheWay, OrderProcessStatus.ReadyForPickUp].includes(status);
};

const isInDelivery = (status: OrderProcessStatus) => {
  return [OrderProcessStatus.OnTheWay].includes(status);
};

const isConfirmation = (status: OrderProcessStatus) => {
  return [
    OrderProcessStatus.Confirmed,
    OrderProcessStatus.Packing,
    OrderProcessStatus.Packed,
    OrderProcessStatus.Confirming,
    OrderProcessStatus.DocumentVerification,
  ].includes(status);
};

const isChatEmpty = (chat: Chat) => chat.total === 1;
const isOrderChatVisible = (order: Order, chat: Chat) =>
  chat.isClosed ? !isChatEmpty(chat) : isDone(order.processStatus) ? !isChatEmpty(chat) : true;

const findChatByType = (chats: Chat[], type: ChatType) => chats.find((c) => c.type === type);
const findChatById = (chats: Chat[], id: ChatId) => chats.find((c) => c.chatId === id);
const getStoreChat = (chats: Chat[]) => findChatByType(chats, ChatType.Store);
const getDriverChat = (chats: Chat[]) => findChatByType(chats, ChatType.Driver);

export type { OrderId, ChatId, Chat, ChatUser, ChatMessage, CourierLocation };
export {
  ChatMessageReader,
  isSuccessDone,
  isOutForDelivery,
  isPacking,
  CHAT_CLOSED_TEXT_MESSAGE,
  isDone,
  getOrderStatusLabel,
  isCanceled,
  isSystem,
  isMessageSent,
  isMessageReadBy,
  isMessageReadByAny,
  isOrderChatVisible,
  findChatByType,
  findChatById,
  getStoreChat,
  getDriverChat,
  getMessageSenderType,
  isConfirmation,
  isDelivering,
  isInDelivery,
  ChatMessageStatus,
};
