import Badge from '@swe/shared/ui-kit/components/badge';

import { PercentIcon } from '@swe/shared/ui-kit/components/icon';
import { PatternThemeProvider } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import styles from './styles.module.scss';

import { Discount } from 'entities/discount/discount';

type PromoBadgeProps = {
  promos?: (Omit<Discount, 'endDate'> & { endDate?: Date })[];
} & ComponentHasClassName;
const PromoBadge = ({ className, promos }: PromoBadgeProps) => {
  if (!promos || promos.length === 0) return null;

  return (
    <PatternThemeProvider name="product-deal">
      <Badge
        className={className}
        color="danger"
        size="md"
        icon={PercentIcon}
      />
    </PatternThemeProvider>
  );
};

type PromoNameProps = ComponentHasClassName & {
  promos?: (Omit<Discount, 'endDate'> & { endDate?: Date })[];
  manyPromosTitle?: string;
};

const PromoName = ({ className, promos = [], manyPromosTitle }: PromoNameProps) => {
  const activePromos = promos.filter(({ isActiveBySchedule }) => isActiveBySchedule);
  const [promo] = activePromos;

  if (!promo) return null;

  return (
    <div className={cx(className, styles.promoName)}>
      {activePromos.length > 1 ? manyPromosTitle || 'Deals' : promo.shortName}
    </div>
  );
};

export type { PromoBadgeProps, PromoNameProps };
export { PromoBadge, PromoName };
