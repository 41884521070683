import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';

import styles from './styles.module.scss';

const ProductImageCarouselSkeleton = () => (
  <Skeleton
    className={styles.skeleton}
    uniqueKey="product_image_carousel"
    height="100%"
  >
    <Bone
      rx="32"
      width="100%"
      height="100%"
    />
  </Skeleton>
);

export { ProductImageCarouselSkeleton };
