import { Link } from '@swe/shared/providers/router/link';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';

import React from 'react';

import FooterCuraleaf from './variants/curaleaf';

import FooterDefault from './variants/default';

import { useStoreConfig } from 'common/providers/config';

import { Routes } from 'common/router/constants';
import { usePoliciesList } from 'common/use-cases/policies';

const Footer = () => {
  const {
    logo,
    title,
    logoUrl,
    copyrightOwner,
    licenseCode,
    isFooterEnabled,
    isCancerWarningEnabled,
    externalNav = [],
    externalNavFooter = [],
    socialMediaLinks = [],
    slogan,
    footerDisclaimer,
  } = useStoreConfig();
  const { policies } = usePoliciesList();
  const theme = useTheme();

  const nav = externalNavFooter.length > 0 ? externalNavFooter : externalNav;

  if (!isFooterEnabled) {
    return null;
  }
  const finalLogoUrl = logoUrl || Routes.Home;

  const copyright = copyrightOwner ? `Copyright © ${new Date().getFullYear()} ${copyrightOwner}.` : '';
  const license = licenseCode ? `License number(s): ${licenseCode}` : undefined;
  let FooterVariant = FooterDefault;
  switch (theme.footer.variant.switch) {
    case 'sacramento':
      FooterVariant = FooterCuraleaf;
      break;
  }

  return (
    <FooterVariant
      externalNav={nav}
      logoSrc={logo}
      logoUrl={finalLogoUrl}
      slogan={slogan}
      title={title}
      copyright={copyright}
      license={license}
      socialMedia={socialMediaLinks}
      disclaimers={[
        isCancerWarningEnabled && (
          <>
            ⚠️ WARNING: These products can expose you to chemicals which are known to the State of California to cause
            cancer, and which are known to the State of California to cause birth defects or other reproductive harm.
            For more information go to{' '}
            <Link
              target="_blank"
              aria-label="P65Warnings site (Opens new window)"
              href="https://www.P65Warnings.ca.gov"
            >
              www.P65Warnings.ca.gov
            </Link>
          </>
        ),
        footerDisclaimer,
      ].filter(Boolean)}
      policies={policies}
    />
  );
};

export { Footer };
export default Footer;
