import cx from 'clsx';

import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { mergeRefs } from 'react-merge-refs';

import placeholderIcon from '@swe/shared/ui-kit/components/image/assets/placeholder.svg';
import SVG from '@swe/shared/ui-kit/components/svg';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type ImgProps = ComponentHasClassName & {
  src?: string | null;
  alt: string;
  title?: string;
  onLoad?: () => void;
  fit?: 'cover' | 'contain';
  lazy?: boolean;
  emptyImage?: 'alt' | 'placeholder';
} & ({ width: number; ratio: number } | { width?: number; ratio?: undefined });

const isImageDataSrc = (src: string) => src.startsWith('data:image');
const widthPresets = [16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840, 5120, 7680];
const getWidthPreset = (width: number) => {
  const maxWidth = 7680;
  const closestPreset = widthPresets.filter((preset) => width <= preset)[0];
  return closestPreset ?? maxWidth;
};

const Img = forwardRef<HTMLImageElement, ImgProps>(
  ({ lazy = true, className, ratio, emptyImage = 'alt', src, alt, onLoad, fit = 'cover', title, width }, ref) => {
    const finalSrc = useMemo(() => {
      let finalSrc = src;
      if (src && !isImageDataSrc(src)) {
        finalSrc = src.replace('sweedpos.s3.amazonaws.com', 'static.sweedpos.com');
        if (width) {
          finalSrc = `${finalSrc}?${new URLSearchParams({ width: String(getWidthPreset(width)) })}`;
        }
      }
      return finalSrc;
    }, [src, width]);
    const _ref = useRef<HTMLImageElement>(null!);
    const [isError, setIsError] = useState(!src);

    useEffect(() => {
      setIsError(!src);
    }, [src]);

    const handleLoad = useCallback(() => {
      if (onLoad) {
        setTimeout(onLoad);
      }
      setIsError(!src);
    }, [onLoad, src]);

    const handleError = useCallback(() => {
      setIsError(true);
    }, []);

    useEffect(() => {
      if (_ref.current && (_ref.current.complete || _ref.current.naturalHeight + _ref.current.naturalWidth > 0)) {
        handleLoad();
      }
    }, [handleLoad]);

    const roundedWidth = width ? Math.ceil(width) : undefined;

    return (
      <div
        className={cx(styles.root, className, ratio && styles._ratio, ratio && styles[`_fit_${fit}`])}
        style={ratio ? { aspectRatio: String(ratio) } : {}}
      >
        {isError && ratio ? (
          <div
            role="img"
            aria-label={alt}
            className={styles.placeholder}
          >
            {emptyImage === 'alt' && (title ?? alt)}
            {emptyImage === 'placeholder' && (
              <SVG
                src={placeholderIcon}
                role="presentation"
                width="70%"
                height="70%"
                className={styles.placeholderImage}
              />
            )}
          </div>
        ) : (
          <img
            className={styles.image}
            ref={mergeRefs([ref, _ref])}
            width={ratio && roundedWidth ? roundedWidth : undefined}
            height={ratio && roundedWidth ? roundedWidth / ratio : undefined}
            alt={alt}
            title={title}
            src={finalSrc ?? undefined}
            draggable={false}
            onLoad={handleLoad}
            onError={handleError}
            loading={lazy ? 'lazy' : 'eager'}
          />
        )}
      </div>
    );
  },
);

export { Img };
export type { ImgProps };
export default Img;
