import { createContext, useContext } from 'react';

import { AppSettings } from '@swe/shop-ui/app/types';

const AppSettingsContext = createContext<AppSettings>(null!);
const AppSettingsProvider = AppSettingsContext.Provider;
const useAppSettings = () => useContext(AppSettingsContext);
const getAppSettings = (): AppSettings => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.__sw;
};

export { AppSettingsProvider, useAppSettings, getAppSettings };
