import { useElementByMousePosition } from '@swe/shared/hooks/use-element-by-mouse-position';
import Image, { ImgProps } from '@swe/shared/ui-kit/components/image';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';
import { ReactNode, useCallback } from 'react';

import styles from './styles.module.scss';

type ProductImageProps = ComponentHasClassName &
  Pick<ImgProps, 'alt' | 'title' | 'lazy'> & { children?: ReactNode; images: string[]; width: number };

const ProductImage = ({ className, children, images, alt, title, width, lazy }: ProductImageProps) => {
  const { elementRef, element, setElementIdx } = useElementByMousePosition(images);

  const handleMouseLeave = useCallback(() => {
    setElementIdx(0);
  }, [setElementIdx]);

  return (
    <div
      ref={elementRef}
      className={cx(className, styles.root)}
      onMouseLeave={handleMouseLeave}
    >
      <Image
        className={styles.image}
        alt={alt}
        title={title}
        src={element}
        ratio={1}
        width={width}
        fit="contain"
        emptyImage="placeholder"
        lazy={lazy}
      />
      {children}
    </div>
  );
};

export type { ProductImageProps };
export { ProductImage };
export default ProductImage;
