import { useCallback } from 'react';

import { GetShopInfoEndpoint } from 'endpoints/shop/get-shop-info';
import { FullShopFulfillmentType, ShopFulfillmentType } from 'entities/shop/info';

export const fulfillmentTypeMap: Record<ShopFulfillmentType, any> = {
  [ShopFulfillmentType.Pickup]: FullShopFulfillmentType.InStorePickup,
  [ShopFulfillmentType.Delivery]: FullShopFulfillmentType.Delivery,
};

const useFulfillmentType = () => {
  const { data: shopInfo, isLoading: isLoadingShopInfo } = GetShopInfoEndpoint.useRequest();

  const getFulfillmentFirstLevel = useCallback(
    (orderType: ShopFulfillmentType) => {
      return shopInfo?.fulfillmentOptions?.find(
        ({ fulfillmentType }) => fulfillmentType === fulfillmentTypeMap[orderType],
      );
    },
    [shopInfo?.fulfillmentOptions],
  );

  const getFulfillmentOptions = useCallback(
    (orderType: ShopFulfillmentType): { value: FullShopFulfillmentType; label: string }[] => {
      const f = getFulfillmentFirstLevel(orderType);
      if (f) {
        const res = [
          {
            value: f.fulfillmentType as FullShopFulfillmentType,
            label: f.title,
          },
        ];
        f.children.forEach(({ fulfillmentType, title }) =>
          res.push({
            value: fulfillmentType as FullShopFulfillmentType,
            label: title,
          }),
        );
        return res;
      }
      return [];
    },
    [getFulfillmentFirstLevel],
  );

  return {
    getFulfillmentFirstLevel,
    getFulfillmentOptions,
    isLoadingShopInfo,
    isCaregiverOrdersEnabled: shopInfo?.isCaregiverOrdersEnabled,
  };
};

export { useFulfillmentType };
export default useFulfillmentType;
