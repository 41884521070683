import Link from '@swe/shared/providers/router/link';
import { usePatternTheme } from '@swe/shared/ui-kit/theme/provider';

import cx from 'clsx';

import { ReactNode, SyntheticEvent } from 'react';

import cssStyles from './styles.module.scss';

import { CommonCardProps } from 'common/components/product-card/types';
import {
  createProductDetailsRoute,
  ProductDetailsRouteBuildFailed,
} from 'common/containers/product-details-modal/config';
import { ProductVariant } from 'entities/product/product';

type WrapperProps = Pick<CommonCardProps, 'className' | 'product' | 'externalId' | 'unavailable' | 'open'> & {
  variant: ProductVariant;
  children: ReactNode;
};

const Wrapper = ({ className, product, variant, externalId, children, unavailable, open }: WrapperProps) => {
  const { className: patternClassName } = usePatternTheme('product-card');
  const commonClassName = [cssStyles.root, className, patternClassName];
  const id = externalId ? `link_${externalId}` : undefined;
  const handleClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
    open(product, variant);
    e.preventDefault();
  };

  try {
    const href = createProductDetailsRoute(product, variant);

    return (
      <Link
        id={id}
        className={cx(...commonClassName, unavailable && cssStyles._unavailable)}
        href={href}
        onClick={handleClick}
        color="ghost"
      >
        {children}
      </Link>
    );
  } catch (e) {
    if (e instanceof ProductDetailsRouteBuildFailed) {
      return (
        <div
          id={id}
          className={cx(...commonClassName, cssStyles._unavailable)}
        >
          {children}
        </div>
      );
    }

    return null;
  }
};

export type { WrapperProps };
export { Wrapper };
