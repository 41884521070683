import { HTMLProps, useCallback, useEffect, useState } from 'react';

import { useRouter, useRouterNavigate } from '@swe/shared/providers/router';
import { Route, RoutePath } from '@swe/shared/providers/router/constants';
import { isAbsoluteURL, isExternalURL, isUtilsURL } from '@swe/shared/providers/router/utils';
import SharedLink, { LinkBaseProps } from '@swe/shared/ui-kit/components/link';

export type LinkProps<R extends RoutePath> = LinkBaseProps &
  Omit<HTMLProps<HTMLAnchorElement>, 'href'> & {
    href: Route<R> | AbsoluteURL | `tel:${string}` | `mailto:${string}`;
    replace?: boolean;
    scroll?: boolean;
  };

export const Link = <R extends RoutePath>({
  children,
  color,
  className,
  href,
  target,
  onClick,
  replace,
  scroll,
  id,
}: LinkProps<R>) => {
  const { buildHref } = useRouter<R>();
  const navigate = useRouterNavigate<R>();
  const isSomethingLikeExternalURL = typeof href === 'string' && (isAbsoluteURL(href) || isUtilsURL(href));
  const hrefStr = isSomethingLikeExternalURL ? href : buildHref(href);
  const [innerTarget, setInnerTarget] = useState<string | null>(null);

  useEffect(() => {
    if (target || !isSomethingLikeExternalURL) return;
    let hrefHost;
    try {
      hrefHost = new URL(href).host;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setInnerTarget(hrefHost === window.location.host ? '_self' : null);
  }, [href, isSomethingLikeExternalURL, target]);

  const clickHandler = useCallback(
    (e: MouseEvent) => {
      const result = onClick?.(e);
      if ((result as any) === false || e.defaultPrevented || isSomethingLikeExternalURL || isExternalURL(href)) {
        return result;
      }
      e.preventDefault();
      void navigate(href as Route<R>, { scroll, replace });
      return result;
    },
    [replace, href, isSomethingLikeExternalURL, onClick, navigate, scroll],
  );

  return (
    <SharedLink
      id={id}
      className={className}
      color={color}
      target={target ?? innerTarget ?? (isSomethingLikeExternalURL ? '_blank' : undefined)}
      onClick={clickHandler}
      href={hrefStr}
    >
      {children}
    </SharedLink>
  );
};

export default Link;
