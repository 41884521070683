class SimpleInMemoryCache {
  private readonly storage = new Map<string, { value: any; createdAt: number; ttl: number }>();

  set<T>(key: string, value: T, ttl: number = 1000 * 60 * 10) {
    this.storage.set(key, { value, createdAt: Date.now(), ttl });
  }

  get<T>(key: string) {
    const entity = this.storage.get(key);
    if (entity && Date.now() - entity.createdAt <= entity.ttl) {
      return entity.value as T;
    }
    this.delete(key);
    return null;
  }

  delete(key: string) {
    if (this.storage.has(key)) {
      this.storage.delete(key);
    }
  }
}

export default new SimpleInMemoryCache();
