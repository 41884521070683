import { useModalRoute } from '@swe/shared/providers/router/modal';
import { useDevice } from '@swe/shared/tools/media';

import { createContext, useCallback, useContext, useMemo } from 'react';

import { Strategy } from 'common/components/product-card/types';
import { createProductDetailsRoute } from 'common/containers/product-details-modal/config';

import { AnalyticsMeta } from 'common/entities/product-analitycs';
import { useStoreConfig } from 'common/providers/config';

import { useRouterHelpers, useRouterNavigate } from 'common/router';
import { ModalRoutes } from 'common/router/constants';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { Product, ProductVariant } from 'entities/product/product';

const pdpQuerySelector = ({
  variant,
  analyticsData,
}: {
  product: Product;
  variant: ProductVariant;
  analyticsData?: AnalyticsMeta;
}) => ({ variantId: variant.id, analyticsData });

const usePDPStrategy = (): Strategy => {
  const config = useStoreConfig();
  const desktop: Strategy = config.isPdpModalDesktopEnabled ?? true ? 'modal' : 'link';
  const tablet: Strategy = config.isPdpModalTabletEnabled ?? true ? 'modal' : 'link';
  const mobile: Strategy = config.isPdpModalMobileEnabled ?? true ? 'modal' : 'link';
  const device = useDevice();
  return useMemo(() => ({ mobile, tablet, desktop })[device], [desktop, tablet, device, mobile]);
};

const PDPRouterContext = createContext<{
  isPdpOpened: boolean;
  isPDPPage: boolean;
  variantId: ProductVariant['id'] | undefined;
  hasPrevious: boolean;
  analyticsData?: AnalyticsMeta;
}>(null!);

type OpenParams = {
  product: Product;
  variant: ProductVariant;
  strategy?: Strategy;
  analyticsData?: AnalyticsMeta;
};

const PDPRouterControlsContext = createContext<{
  close: () => void;
  open: (params: OpenParams) => void;
}>(null!);

const PDPRouterProvider = ({ children }: any) => {
  const strategy = usePDPStrategy();
  const { buildHref } = useRouterHelpers();
  const navigate = useRouterNavigate();

  const pdpUrlDecorator = useCallback(
    (params?: { product: Product; variant: ProductVariant }) => {
      if (!params) {
        return undefined;
      }
      const { product, variant } = params;
      const pdpRoute = createProductDetailsRoute(product, variant);
      return buildHref(pdpRoute);
    },
    [buildHref],
  );
  const {
    open: openModalRoute,
    close,
    isOpened: isPdpOpened,
    params: { variantId: variantIdFromModalQuery, analyticsData },
    hasPrevious,
  } = useModalRoute<
    ModalRoutes,
    { product: Product; variant: ProductVariant; analyticsData?: AnalyticsMeta },
    { variantId: VariantId; analyticsData?: AnalyticsMeta }
  >(ModalRoutes.ProductDetails, pdpQuerySelector, pdpUrlDecorator);
  const { catalogQuery } = useCatalogQuery();
  const isPDPPage = catalogQuery.page === 'product';

  const open = useCallback(
    (data: OpenParams) => {
      const { product, variant, strategy: _strategy, analyticsData } = data;
      const __strategy = _strategy ?? strategy;
      if (__strategy === 'modal') {
        window.performance.mark('open-pdp-start');
        void openModalRoute({ product, variant, analyticsData });
      } else {
        void navigate(createProductDetailsRoute(product, variant, analyticsData));
      }
    },
    [openModalRoute, navigate, strategy],
  );

  const variantIdFromPath = catalogQuery.page === 'product' ? catalogQuery.variantId : undefined;
  const variantId = strategy === 'modal' ? variantIdFromModalQuery : variantIdFromPath;

  return (
    <PDPRouterContext.Provider
      value={useMemo(
        () => ({
          isPdpOpened,
          isPDPPage,
          variantId,
          hasPrevious,
          analyticsData,
        }),
        [analyticsData, hasPrevious, isPDPPage, isPdpOpened, variantId],
      )}
    >
      <PDPRouterControlsContext.Provider value={useMemo(() => ({ open, close }), [open, close])}>
        {children}
      </PDPRouterControlsContext.Provider>
    </PDPRouterContext.Provider>
  );
};

const usePDP = () => useContext(PDPRouterContext);
const usePDPControls = () => useContext(PDPRouterControlsContext);

export { PDPRouterProvider, usePDP, usePDPStrategy, usePDPControls };
