// eslint-disable-next-line max-classes-per-file
import { initializeApp, getApps, getApp, FirebaseOptions, FirebaseApp } from 'firebase/app';
import { getMessaging, getToken as getFCMToken, deleteToken, Messaging, isSupported } from 'firebase/messaging';

import BrowserStorage from '@swe/shared/providers/persist-state/browser-storage';
import { RoutePath } from '@swe/shared/providers/router/constants';
import { EventEmitter } from '@swe/shared/tools/event-emitter';
import { consoleLogger } from '@swe/shared/tools/logger/console';
import { isSSR } from '@swe/shared/utils/environment';

class ServiceWorkerUtils {
  static getServiceWorkerRegistration() {
    return navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope');
  }

  static async checkServiceWorkerActive() {
    const registration = await ServiceWorkerUtils.getServiceWorkerRegistration();

    return !!registration?.active;
  }

  static async postMessage(message: any) {
    const registration = (await ServiceWorkerUtils.getServiceWorkerRegistration())?.active;
    if (registration) {
      registration.postMessage(message);
    }
  }

  static async onServiceWorkerActive(cb: () => any) {
    if (await ServiceWorkerUtils.checkServiceWorkerActive()) {
      cb();
      return;
    }

    let _try = 0;
    const interval = setInterval(async () => {
      _try += 1;
      if (_try > 10) {
        clearInterval(interval);
      }

      const isReady = await ServiceWorkerUtils.checkServiceWorkerActive();
      if (isReady) {
        cb();
        clearInterval(interval);
      }
    }, 1000);
  }
}

class NotificationPermissionUtils {
  static getPermissionStatus() {
    if (typeof window === 'undefined' || typeof Notification === 'undefined') return 'default';

    return Notification.permission;
  }

  static isPermissionGranted() {
    return NotificationPermissionUtils.getPermissionStatus() === 'granted';
  }

  static isPermissionDefault() {
    return NotificationPermissionUtils.getPermissionStatus() === 'default';
  }

  static isPermissionDenied() {
    return NotificationPermissionUtils.getPermissionStatus() === 'denied';
  }

  static async requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'denied') {
      throw new Error('Permission request has been denied!');
    }
  }
}

class SubscriptionUtils {
  static readonly TOKEN_KEY = 'FCMTK';

  private static storage = new BrowserStorage();

  static setToken(token: string) {
    SubscriptionUtils.storage.setItem(SubscriptionUtils.TOKEN_KEY, token);
  }

  static hasToken() {
    return !!SubscriptionUtils.getToken();
  }

  static getToken() {
    return SubscriptionUtils.storage.getItem<string>(SubscriptionUtils.TOKEN_KEY);
  }

  static deleteToken() {
    SubscriptionUtils.storage.setItem(SubscriptionUtils.TOKEN_KEY, null);
  }
}

enum Errors {
  NoMessaging = 'Messaging is not initialized!',
  NoSSR = 'SSR is not supported!',
}

class FCMService extends EventEmitter<{
  onChange: { isSubscribed: boolean };
  onInit: undefined;
  onTokenReceived: { token: string };
  onTokenDeleted: undefined;
  onSubscribe: undefined;
  onUnsubscribe: undefined;
}> {
  private app?: FirebaseApp;

  private messaging?: Messaging;

  public isInitialized: boolean | null = null;

  constructor(private readonly config: FirebaseOptions) {
    super();
  }

  public get appId() {
    return this.config.appId;
  }

  public async init() {
    if (isSSR) return;
    if (!(await isSupported())) return;

    try {
      if (getApps().length === 0) {
        this.app = initializeApp(this.config);
      } else {
        this.app = getApp();
      }

      this.messaging = getMessaging(this.app);
      this.fire('onChange', { isSubscribed: SubscriptionUtils.hasToken() });
      this.fire('onInit', undefined);
      this.isInitialized = true;
    } catch (e) {
      this.isInitialized = false;
    }
  }

  private async getToken() {
    if (!this.messaging) {
      throw new Error(Errors.NoMessaging);
    }

    const token = await getFCMToken(this.messaging);
    SubscriptionUtils.setToken(token);

    return token;
  }

  public async makeSubscription(storeId: number, routeMap: Record<number, RoutePath | null>, externalApiUri?: string) {
    if (isSSR) {
      consoleLogger.log(Errors.NoSSR);
      return;
    }

    if (!this.messaging) {
      consoleLogger.log(Errors.NoMessaging);
      return;
    }

    try {
      await NotificationPermissionUtils.requestPermission();
    } catch (e) {
      consoleLogger.error('Permission has not been granted', e);
    }

    try {
      await this.getToken();
    } catch (e) {
      /**
       * The following part of the code serves the purpose of fixing a bug
       * when FCM runs getToken before the service worker has been activated.
       */
      consoleLogger.error('Token retrieving has failed', e);
      await new Promise((resolve, reject) => {
        void ServiceWorkerUtils.onServiceWorkerActive(() => {
          this.getToken().then(resolve).catch(reject);
        });
      });
    }

    void ServiceWorkerUtils.onServiceWorkerActive(() =>
      ServiceWorkerUtils.postMessage({
        type: 'init',
        data: { storeId, routeMap, externalApiUri },
      }),
    );
    this.fire('onSubscribe', undefined);
  }

  public async deleteSubscription() {
    if (!this.messaging) {
      consoleLogger.log(Errors.NoMessaging);
      return;
    }

    await deleteToken(this.messaging);
    SubscriptionUtils.deleteToken();
    this.fire('onUnsubscribe', undefined);
  }
}

export { FCMService, ServiceWorkerUtils, SubscriptionUtils, NotificationPermissionUtils };
