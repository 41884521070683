import Button from '@swe/shared/ui-kit/components/button';
import { FocusTrap } from '@swe/shared/ui-kit/components/focus-trap';
import { CloseIcon } from '@swe/shared/ui-kit/components/icon';

import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import { Stack } from '@swe/shared/ui-kit/components/stack';

import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import { forwardRef } from 'react';

import styles from './styles.module.scss';

type PromoSnackProps = ComponentHasChildren &
  ComponentHasClassName & {
    close: () => void;
    heading?: string;
    action?: {
      label: string;
      onClick: () => void;
    };
    color?: 'default' | 'brand';
    withoutFocusTrap?: boolean;
  };

const PromoSnack = forwardRef(
  ({ close, heading, action, children, className, color, withoutFocusTrap }: PromoSnackProps, ref) => (
    <FocusTrap
      isActive={!withoutFocusTrap}
      initialFocus
    >
      <Stack
        className={cx(styles.root, className, color && color !== 'default' && styles[`_color_${color}`])}
        ref={ref}
      >
        <SectionHeading
          size="sm"
          colorText="inherit"
          noPadding
          button={{
            icon: CloseIcon,
            onClick: close,
          }}
        >
          {heading}
        </SectionHeading>
        <div className={styles.body}>{children}</div>
        {action && (
          <Button
            type="button"
            ariaLabel="Discount"
            size="sm"
            onClick={action.onClick}
            color={color === 'brand' ? 'light' : 'primary'}
          >
            {action.label}
          </Button>
        )}
      </Stack>
    </FocusTrap>
  ),
);

export default PromoSnack;
