import { CalendarIcon, CarDeliveryIcon, ClockIcon, DeliveryIcon } from '@swe/shared/ui-kit/components/icon';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

import { SimplifiedSchedule } from 'entities/common/schedule';
import { DicsountFulfillmentType } from 'entities/discount/discount';

type DiscountInfoProps = {
  schedule?: SimplifiedSchedule;
  fulfilmentTypes?: DicsountFulfillmentType[];
} & ComponentHasClassName;

const DiscountInfo = ({ className, schedule, fulfilmentTypes = [] }: DiscountInfoProps) => {
  const { rangeType, days, datePart, timePart: timeInterval } = schedule ?? {};
  const endDate = [rangeType, days, datePart].filter(Boolean).join(', ');

  if (!endDate && !timeInterval && !fulfilmentTypes.length) {
    return null;
  }

  return (
    <div className={cx(styles.root, className)}>
      {endDate && (
        <div className={styles.infoBlock}>
          <CalendarIcon />
          {endDate}
        </div>
      )}
      {timeInterval && (
        <div className={styles.infoBlock}>
          <ClockIcon />
          {timeInterval}
        </div>
      )}
      {fulfilmentTypes.length > 0 && (
        <div className={styles.infoBlock}>
          {fulfilmentTypes.includes(DicsountFulfillmentType.Delivery) ? <CarDeliveryIcon /> : <DeliveryIcon />}
          {fulfilmentTypes.join(', ')}
          {fulfilmentTypes.length === 1 && ' only'}
        </div>
      )}
    </div>
  );
};

export default DiscountInfo;
export { DiscountInfo };
