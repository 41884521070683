import { Route, RoutePath } from '@swe/shared/providers/router/constants';
import Link from '@swe/shared/providers/router/link';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type BreadcrumbProps<R extends RoutePath> = ComponentHasChildren & {
  href?: Route<R>;
  isLast?: boolean;
};

const Breadcrumb = <R extends RoutePath>({ children, href, isLast }: BreadcrumbProps<R>) => {
  const content = !isLast && href ? <Link href={href}>{children}</Link> : children;
  return <div>{content}</div>;
};

export type { BreadcrumbProps };
export { Breadcrumb };
