import { RoutePath, RouteQuery } from '@swe/shared/providers/router/constants';

import { Routes } from '@swe/shop-ui/common/router/constants';

type RedirectConfig = {
  source: RoutePath;
  destination: RoutePath;
  queryMapper?: (input: RouteQuery) => Promise<RouteQuery>;
  permanent: boolean;
};

const REDIRECTS: RedirectConfig[] = [
  {
    source: '/[[saleType]]/sign-in',
    destination: Routes.SignIn,
    permanent: true,
  },
  {
    source: '/[[saleType]]/about-store',
    destination: Routes.StoreAbout,
    permanent: true,
  },
  {
    source: '/[[saleType]]/loyalty',
    destination: Routes.Profile,
    permanent: true,
  },
  {
    source: '/[[saleType]]/orders',
    destination: Routes.ProfileOrders,
    permanent: true,
  },
  {
    source: '/[[saleType]]/orders/order/[id]',
    destination: Routes.ProfileOrderDetails,
    permanent: true,
  },
  {
    source: '/[[saleType]]/orders/order/info/[id]',
    destination: Routes.ProfileOrderDetails,
    permanent: true,
  },
  {
    source: '/[[saleType]]/pass-recovery',
    destination: Routes.ProfilePasswordChange,
    permanent: true,
  },
  {
    source: '/[[saleType]]/agreements/privacy-policy/actual',
    destination: Routes.AgreementsPrivacyPolicy,
    permanent: true,
  },
  {
    source: '/[[saleType]]/discounts',
    destination: Routes.Discounts,
    permanent: true,
  },
  {
    source: '/[[saleType]]/discount/[id]',
    destination: `${Routes.Discounts}/[id]`,
    permanent: true,
  },
  {
    source: '/[[saleType]]/variant/[[...all]]',
    queryMapper: async (query) => {
      const variantId =
        Array.isArray(query.all) && query.all.length > 0
          ? (query.all[query.all.length - 1] as EntityID<string>)
          : undefined;

      return {
        ...query,
        all: ['category-123', String(variantId)],
      };
    },
    destination: Routes.Catalog,
    permanent: true,
  },
];

const TRUNCATED_SHOP_REDIRECTS: RedirectConfig[] = [
  Routes.Home,
  Routes.Catalog,
  Routes.Discounts,
  Routes.Cart,
  Routes.Checkout,
  Routes.CheckoutDocuments,
  Routes.CheckoutDocumentsCategory,
  Routes.ProfileOrders,
  Routes.ProfileDocuments,
  Routes.ProfilePromo,
  Routes.ProfileReferralProgram,
  Routes.ProfileOrders,
  Routes.ProfileOrderDetails,
  '*' as Routes,
].map((route) => ({
  source: route,
  destination: Routes.Profile,
  permanent: true,
}));

export default REDIRECTS;

export { TRUNCATED_SHOP_REDIRECTS };
