enum NotificationType {
  Transactional = 'Transactional',
  Marketing = 'Marketing',
}

enum NotificationMethod {
  Sms = 'Sms',
  Email = 'Email',
  Push = 'Push',
}

type Notification = {
  notificationMethod: NotificationMethod;
  name: string;
  enabled: boolean;
  suspendUntilDate?: DateISOString;
};

const matchMethod = (notification: Notification, method: NotificationMethod) =>
  notification.notificationMethod === method;

export type { Notification };
export { NotificationType, NotificationMethod, matchMethod };
