import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import Text from '@swe/shared/ui-kit/components/text';

import React from 'react';

import { ProductSection } from 'common/components/product-section';
import { SectionSkeleton } from 'domains/product/components/product-details/common/section-skeleton';
import { Product } from 'entities/product/product';

type ProductDescriptionProps = {
  product: Product;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProductDescription = ({ product }: ProductDescriptionProps) => {
  if (!product.description) {
    return null;
  }
  const lines: string[] = product.description.split('\n');

  return (
    <ProductSection
      title="Product Description"
      expandable
      defaultExpanded={false}
    >
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {!!line && <Text size="lg">{line}</Text>}
          {index < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </ProductSection>
  );
};

const ProductDescriptionSkeletonized = withSkeleton(ProductDescription, SectionSkeleton, ['product']);

export type { ProductDescriptionProps };
export { ProductDescription, ProductDescriptionSkeletonized };
export default ProductDescription;
