import { Device } from '@swe/shared/tools/media';

import { isSSR } from '@swe/shared/utils/environment';
import UAParser from 'ua-parser-js';

import { CLIENT_WIDTH_BY_DEVICE_TYPE } from '@swe/shop-ui/app/config';

const parseUA = (ua?: string) => {
  const {
    device: { type: _deviceType },
    os: { name: _osName },
    browser,
  } = UAParser(ua);

  const isAndroid = !isSSR && !!_osName?.startsWith('Android');
  const isIOS = !isSSR && _osName === 'iOS';
  const isOpera = !isSSR && !!browser.name?.includes('Opera');

  const device = (['mobile', 'tablet'].includes(_deviceType!) ? _deviceType : 'desktop') as Device;
  return {
    device,
    isAndroid,
    isIOS,
    isOpera,
  };
};

const getClientWidthFallback = ({ deviceType, userAgent }: { deviceType?: string; userAgent?: string }) => {
  if (deviceType && ['mobile', 'tablet', 'desktop'].includes(deviceType)) {
    return CLIENT_WIDTH_BY_DEVICE_TYPE[deviceType as Device];
  }

  const { device } = parseUA(userAgent);
  return CLIENT_WIDTH_BY_DEVICE_TYPE[device ?? 'mobile'];
};

export { parseUA, getClientWidthFallback };
