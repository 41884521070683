import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import Link from '@swe/shared/providers/router/link';
import AnimatedHeight from '@swe/shared/ui-kit/components/animated-height';
import Button from '@swe/shared/ui-kit/components/button';
import { Feature } from '@swe/shared/ui-kit/components/feature';
import {
  BarsIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DropletIcon,
  EffectsIcon,
  HashIcon,
  IconComponent,
  JarIcon,
  LabelIcon,
  LeafIcon,
  LongArrowDownRightIcon,
  OrangeSliceIcon,
  PackSizeIcon,
  TerpeneCaryophylleneIcon,
  TerpeneLimoneneIcon,
  TerpeneLinaloolIcon,
  TerpeneMyrceneIcon,
  TerpeneOtherIcon,
  TerpenePineneAbIcon,
  UnitsIcon,
  UnitSizeIcon,
} from '@swe/shared/ui-kit/components/icon';

import { Progress } from '@swe/shared/ui-kit/components/progress';
import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';
import { findLastIndex } from '@swe/shared/utils/array';
import { capitalize } from '@swe/shared/utils/string';
import cx from 'clsx';

import { ReactNode, useMemo } from 'react';

import { AttributesSkeleton } from './skeleton';

import styles from './styles.module.scss';

import { ProductSection } from 'common/components/product-section';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import {
  formatLabTestValue,
  Product,
  ProductLaboratoryData,
  ProductLabTest,
  ProductUOMAbbr,
  ProductVariant,
  TerpeneName,
} from 'entities/product/product';

const MAX_ATTRS_PER_SHORT_INFO = 4;

const THC_DESCRIPTION_TEMPLATE =
  'This {{unitAbbr}} may represent an aggregate of THC, THCa, THCb, and/or terpenes within the product. Consumers should review the actual product label for exact {{unitAbbr}} of THC and/or terpenes.';
const CBD_DESCRIPTION_TEMPLATE =
  'This {{unitAbbr}} may represent an aggregate of CBD, CBDa, CBDb, and/or terpenes within the product. Consumers should review the actual product label for exact {{unitAbbr}} of THC/CBD and/or terpenes.';

const createDescription = (substance: ProductLabTest, unitAbbr: ProductUOMAbbr) => {
  const content = unitAbbr === 'MG' ? ['Value is mg/package'] : [];
  switch (substance) {
    case ProductLabTest.CBD:
      content.push(CBD_DESCRIPTION_TEMPLATE.replaceAll('{{unitAbbr}}', unitAbbr));
      break;
    case ProductLabTest.THC:
      content.push(THC_DESCRIPTION_TEMPLATE.replaceAll('{{unitAbbr}}', unitAbbr));
      break;
  }

  return content.length ? content : undefined;
};

enum Attrs {
  TOTAL_THC,
  TOTAL_CBD,
  FLAVORINGS,
  FLAVORS,
  TERPENES,
  EFFECTS,
  STRAIN,
  UNITS_IN_PCK,
  UNIT_SIZE,
  PACK_SIZE,
  TAGS,
  SUBCATEGORY,
  PREVALENCE,
  QUALITY_LINE,
  SCENTS,
}

const ATTRS_ORDER = [
  Attrs.TOTAL_THC,
  Attrs.TOTAL_CBD,
  Attrs.PREVALENCE,
  Attrs.EFFECTS,
  Attrs.SUBCATEGORY,
  Attrs.QUALITY_LINE,
  Attrs.STRAIN,
  Attrs.FLAVORS,
  Attrs.FLAVORINGS,
  Attrs.SCENTS,
  Attrs.TAGS,
  Attrs.PACK_SIZE,
  Attrs.UNITS_IN_PCK,
  Attrs.UNIT_SIZE,

  // Attrs.TERPENES,
];

type ProductAttributesProps = {
  product: Product;
  variant: ProductVariant;
  labData?: ProductLaboratoryData;
  isLabDataLoading?: boolean;
  onShowMoreLabData?: () => void;
};

type AttributeValueProps = {
  name: string;
  filter?: Record<string, any>;
};

const AttributeValue = ({ name, filter }: AttributeValueProps) => {
  const { buildCatalogLink } = useCatalogQuery();
  if (filter) {
    return (
      <Link href={buildCatalogLink({ filters: filter, subPath: null, searchTerm: null, page: null })}>#{name}</Link>
    );
  }

  return <span>{name}</span>;
};

type ProbablyAttribute = NamedEntity | LinkableEntity;
type ProbablyAttributes = ProbablyAttribute[] | ProbablyAttribute;

type AttributesProps<PT extends ProbablyAttributes> = {
  attributes: PT;
  renderName?: (attr: PT) => string;
};
const Attribute = <PT extends ProbablyAttributes>({ attributes, renderName }: AttributesProps<PT>) => {
  if (Array.isArray(attributes)) {
    return (
      <div className={styles.attributesList}>
        {attributes.map((attribute) => (
          <AttributeValue
            key={attribute.name}
            name={renderName?.(attribute as PT) ?? attribute.name}
            filter={'filter' in attribute ? attribute.filter : undefined}
          />
        ))}
      </div>
    );
  }

  return (
    <AttributeValue
      name={renderName?.(attributes as PT) ?? attributes.name}
      filter={'filter' in attributes ? attributes.filter : undefined}
    />
  );
};

const renderAttributes = <PT extends ProbablyAttributes>({ attributes, renderName }: Partial<AttributesProps<PT>>) => {
  if ((Array.isArray(attributes) && attributes.length === 0) || !attributes) return undefined;

  return (
    <Attribute
      attributes={attributes}
      renderName={renderName}
    />
  );
};

const getTerpeneIcon = (code: string) => {
  const ICON_MAP: Record<TerpeneName, IconComponent | undefined> = {
    A_PINENE: TerpenePineneAbIcon,
    BISABOLOL: undefined,
    BORNEOL: undefined,
    B_CARYOPHYLLENE: TerpeneCaryophylleneIcon,
    B_MYRCENE: TerpeneMyrceneIcon,
    B_PINENE: TerpenePineneAbIcon,
    CAMPHENE: undefined,
    CAMPHOR: undefined,
    CARENE: undefined,
    CAROPHYLLENE_OXIDE: TerpeneCaryophylleneIcon,
    CEDRENE: undefined,
    CYMENE: undefined,
    EUCALYPTOL: undefined,
    FENCHOL: undefined,
    GERANIOL: undefined,
    GERANYL_ACETATE: undefined,
    GUAIOL: undefined,
    HUMULENE: undefined,
    ISOBORNEOL: undefined,
    ISOPULEGOL: undefined,
    LIMONENE: TerpeneLimoneneIcon,
    LINALOOL: TerpeneLinaloolIcon,
    MENTHOL: undefined,
    OCIMENE: undefined,
    PHELLANDRENE: undefined,
    PHYTOL: undefined,
    PINENE: TerpenePineneAbIcon,
    PULEGONE: undefined,
    P_CYMENE: undefined,
    SABINENE: undefined,
    TERPINENE: undefined,
    TERPINEOL: undefined,
    TERPINOLENE: undefined,
    TRANS_NEROLIDOL: undefined,
    VALENCENE: undefined,
    Y_TERPINENE: undefined,
  };
  return ICON_MAP[code as TerpeneName] ?? TerpeneOtherIcon;
};

const ProductAttributes = ({
  product,
  variant,
  labData,
  onShowMoreLabData,
  isLabDataLoading,
}: ProductAttributesProps) => {
  type Attribute = {
    label: string;
    content?: ReactNode;
    icon?: IconComponent;
    info?: ReactNode;
    isGrouped?: boolean;
  };
  const [isOtherExpanded, , , toggleOther] = useToggleable(false);

  const attrs: Attribute[] = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      ATTRS_ORDER.map((attr) => {
        switch (attr) {
          case Attrs.TOTAL_THC:
            return {
              label: 'Total THC',
              content: variant.labTests?.thc ? formatLabTestValue(variant.labTests?.thc) : null,
              info: variant.labTests?.thc && createDescription(ProductLabTest.THC, variant.labTests.thc.unitAbbr),
            };
          case Attrs.TOTAL_CBD:
            return {
              label: 'Total CBD',
              content: variant.labTests?.cbd ? formatLabTestValue(variant.labTests?.cbd) : null,
              info: variant.labTests?.cbd && createDescription(ProductLabTest.CBD, variant.labTests.cbd.unitAbbr),
            };
          case Attrs.SUBCATEGORY:
            return {
              label: 'Subcategory',
              icon: LongArrowDownRightIcon,
              content: renderAttributes({ attributes: product.subcategory }),
            };
          case Attrs.QUALITY_LINE:
            return {
              label: 'Quality line',
              icon: LabelIcon,
              content: renderAttributes({ attributes: product.qualityLine }),
            };
          case Attrs.PREVALENCE:
            return {
              label: 'Strain Prevalence',
              icon: LeafIcon,
              content: renderAttributes({ attributes: product.strain?.prevalence }),
            };
          case Attrs.SCENTS:
            return {
              label: 'Scents',
              icon: OrangeSliceIcon,
              content: renderAttributes({ attributes: product.scents }),
            };
          case Attrs.FLAVORS:
            return {
              label: 'Flavors',
              icon: DropletIcon,
              content: renderAttributes({ attributes: product.strain?.flavors }),
            };
          case Attrs.FLAVORINGS:
            return {
              label: 'Flavorings',
              icon: DropletIcon,
              content: renderAttributes({ attributes: product.flavorings }),
            };
          case Attrs.TERPENES:
            return {
              label: 'Terpenes',
              icon: TerpeneOtherIcon,
              content: renderAttributes({ attributes: product.strain?.terpenes }),
            };
          case Attrs.EFFECTS:
            return {
              label: 'Effects',
              icon: EffectsIcon,
              content: renderAttributes({ attributes: product.effects }),
            };
          case Attrs.STRAIN:
            return {
              label: 'Strain',
              icon: JarIcon,
              content: renderAttributes({ attributes: product.strain }),
            };
          case Attrs.UNITS_IN_PCK:
            return {
              label: 'Units in package',
              icon: UnitsIcon,
              content: variant.unitsInPackage === 1 ? undefined : variant.unitsInPackage,
              isGrouped: true,
            };
          case Attrs.UNIT_SIZE:
            return {
              label: 'Unit size',
              icon: UnitSizeIcon,
              content:
                variant.unitsInPackage === 1
                  ? undefined
                  : renderAttributes({
                      attributes: variant.unitSize,
                      renderName: (v) => `${v.value}${v.unitAbbr}`,
                    }),
              isGrouped: true,
            };
          case Attrs.PACK_SIZE:
            return {
              label: 'Total size',
              icon: PackSizeIcon,
              content:
                variant.unitSize && variant.unitsInPackage
                  ? `${variant.unitSize.value * variant.unitsInPackage}${variant.unitSize.unitAbbr}`
                  : undefined,
              isGrouped: true,
            };
          case Attrs.TAGS:
            return {
              label: 'Tags',
              icon: HashIcon,
              content: renderAttributes({ attributes: product.tags }),
            };
        }
      }).filter((attr) => !!attr.content),
    [product, variant],
  );

  const { infoAttrs, otherAttrs } = useMemo(() => {
    const groupStartIndex = attrs.findIndex((attr) => !!attr.isGrouped);
    const groupEndIndex = findLastIndex(attrs, (attr) => !!attr.isGrouped);
    if (groupStartIndex < MAX_ATTRS_PER_SHORT_INFO && groupEndIndex >= MAX_ATTRS_PER_SHORT_INFO) {
      return {
        infoAttrs: attrs.slice(0, groupStartIndex),
        otherAttrs: attrs.slice(groupStartIndex),
      };
    }
    return {
      infoAttrs: attrs.slice(0, MAX_ATTRS_PER_SHORT_INFO),
      otherAttrs: attrs.slice(MAX_ATTRS_PER_SHORT_INFO),
    };
  }, [attrs]);

  const topTerps = useMemo(() => {
    return (labData?.terpenes?.values ?? []).slice(0, 4).map((labValue) => ({
      label: labValue.name,
      icon: getTerpeneIcon(labValue.code),
      content: formatLabTestValue({ value: [labValue.min, labValue.max], unitAbbr: labData!.terpenes.unitAbbr! }),
    }));
  }, [labData]);

  return (
    <>
      <ProductSection title="Info">
        <div className={styles.attributes}>
          {variant.strengthLevel && (
            <Feature
              key="strength-level"
              className={styles.strength}
              title={`Strength Level: ${capitalize(variant.strengthLevel.name)}`}
              subtitle={
                <Progress
                  variant="discrete"
                  className={styles.strengthProgress}
                  value={variant.strengthLevel.level}
                  divisions={variant.strengthLevel.maxLevel}
                />
              }
            />
          )}
          {infoAttrs.map(({ label, content, icon, info }) => (
            <Feature
              key={label}
              icon={icon}
              title={label}
              subtitle={content}
              info={info}
            />
          ))}
        </div>
      </ProductSection>
      {topTerps.length > 0 && (
        <ProductSection title="Top Terpenes">
          <div className={styles.attributes}>
            {topTerps.map(({ label, content, icon }) => (
              <Feature
                key={label}
                icon={icon}
                title={label}
                subtitle={content}
              />
            ))}
          </div>
        </ProductSection>
      )}
      {(otherAttrs.length > 0 || labData) && (
        <div>
          <AnimatedHeight
            expanded={isOtherExpanded}
            transition
          >
            <ProductSection title="Other">
              <div className={cx(styles.attributes, styles.attributes_other)}>
                {otherAttrs.map(({ label, content, icon }) => (
                  <Feature
                    key={label}
                    icon={icon}
                    title={label}
                    subtitle={content}
                  />
                ))}
              </div>
            </ProductSection>
          </AnimatedHeight>
          <div className={styles.moreInfoButtons}>
            {otherAttrs.length > 0 && (
              <Button
                className={styles.moreInfoButton}
                onClick={toggleOther}
                ariaLabel="More Info"
                color="light"
                size="xs"
                icon={BarsIcon}
                endIcon={!isOtherExpanded ? ChevronDownIcon : ChevronUpIcon}
              >
                More Info
              </Button>
            )}
            {(labData || isLabDataLoading) && (
              <Button
                className={styles.moreInfoButton}
                pending={isLabDataLoading}
                onClick={onShowMoreLabData}
                ariaLabel="Full Lab Data"
                color="light"
                size="xs"
                icon={BarsIcon}
              >
                Full Lab Data
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const ProductAttributesSkeletonized = withSkeleton(ProductAttributes, AttributesSkeleton, ['product', 'variant']);

export type { ProductAttributesProps };
export { ProductAttributes, ProductAttributesSkeletonized };
export default ProductAttributes;
