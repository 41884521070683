import { XSweedHeader } from '@swe/shared/network/endpoint-factories/modern/fetcher';
import { QueryClient } from '@swe/shared/network/transport/query';
import { CommonQueryParams, RouteQuery } from '@swe/shared/providers/router/constants';
import { stringifyRouteUrl } from '@swe/shared/providers/router/utils';
import { defaultLanguages, defaultLocale, IntlCore } from '@swe/shared/tools/intl/core/i18n';
import { getIsPWA } from '@swe/shared/tools/is-pwa';
import { ErrorBoundary } from '@swe/shared/ui-kit/components/error-boundary';
import { isSSR } from '@swe/shared/utils/environment';

import { capitalize } from '@swe/shared/utils/string';

import { RouteObject, redirect, generatePath } from 'react-router-dom';

import AppLayout from '@swe/shop-ui/app/layout';
import REDIRECTS, { TRUNCATED_SHOP_REDIRECTS } from '@swe/shop-ui/app/router/redirects';
import { ApplicationPage, PageLoaderData } from '@swe/shop-ui/app/types';
import { Routes } from '@swe/shop-ui/common/router/constants';
import { convertReactToSharedQuery, convertSharedToReactRoute } from '@swe/shop-ui/common/router/utils';
import { getPlatformOs } from '@swe/shop-ui/common/use-cases/use-platform-os';
import { ShopConfig } from '@swe/shop-ui/entities/shop/config';
import { getSaleTypeFromReq } from 'app/app-settings/provider';
import ErrorPage, { RootErrorPage } from 'app/error-page';
import { parseUA } from 'app/utils';
import logger from 'app/utils/logger';
import { DEFAULT_SALE_TYPE, isExistedSaleTypeParam } from 'common/use-cases/use-sale-type';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';
import { StoreSaleType } from 'entities/shop/sale-type';

type StoreRouterConfig = Pick<
  ShopConfig,
  | 'id'
  | 'translations'
  | 'medicalMenuEnabled'
  | 'saleTypeBasedRoutingEnabled'
  | 'routeName'
  | 'isTruncatedShop'
  | 'dealerName'
>;

const getSaleType = ({
  cookie,
  medicalMenuEnabled,
  saleTypeBasedRoutingEnabled,
  routeName,
  route,
  query,
}: { cookie?: string; route: Routes; query: RouteQuery } & Pick<
  StoreRouterConfig,
  'saleTypeBasedRoutingEnabled' | 'medicalMenuEnabled' | 'routeName'
>) => {
  let saleType = getSaleTypeFromReq(medicalMenuEnabled, cookie);

  if (
    (!saleTypeBasedRoutingEnabled || !medicalMenuEnabled) &&
    isExistedSaleTypeParam(query[CommonQueryParams.SaleType] as string)
  ) {
    throw new Response('', {
      status: 301,
      headers: {
        Location: stringifyRouteUrl(
          {
            pathname: route,
            basePath: routeName!,
            query: {
              ...query,
              [CommonQueryParams.SaleType]: undefined,
            },
          },
          true,
        ),
      },
    });
  }

  if (
    saleTypeBasedRoutingEnabled &&
    medicalMenuEnabled &&
    !isExistedSaleTypeParam(query[CommonQueryParams.SaleType] as string)
  ) {
    throw new Response('', {
      status: 301,
      headers: {
        Location: stringifyRouteUrl(
          {
            pathname: route,
            basePath: routeName!,
            query: {
              ...query,
              [CommonQueryParams.SaleType]: (saleType ?? DEFAULT_SALE_TYPE).toLowerCase(),
            },
          },
          true,
        ),
      },
    });
  }

  if (saleTypeBasedRoutingEnabled && medicalMenuEnabled) {
    saleType = capitalize(query[CommonQueryParams.SaleType] as string) as StoreSaleType;
  }

  return saleType;
};

const createMigratedRoute = (
  route: Routes,
  index: boolean,
  storeConfig: StoreRouterConfig,
  globalQueryClient: QueryClient | undefined,
  intl: IntlCore,
): RouteObject => ({
  path: convertSharedToReactRoute(route),
  shouldRevalidate: ({ currentUrl, nextUrl }) => currentUrl.pathname !== nextUrl.pathname,
  lazy: async () => {
    const Component: ApplicationPage = (await import(`../../pages${route}/index.tsx`)).default;
    const { preload, getMainLayoutProps, getMeta } = Component;

    return {
      element: (
        <ErrorBoundary level="fatal">
          <Component />
        </ErrorBoundary>
      ),
      loader: async ({ params, request, context }): Promise<PageLoaderData> => {
        const queryClient = context?.queryClient ?? globalQueryClient;
        const url = new URL(request.url);
        const query: RouteQuery = convertReactToSharedQuery(params, url.searchParams);
        const platformOs = getPlatformOs({
          isPWA: getIsPWA(),
          isAndroid: parseUA(isSSR ? undefined : navigator.userAgent).isAndroid,
        });

        const saleType = getSaleType({
          cookie: isSSR ? request.headers.get('cookie') ?? undefined : undefined,
          medicalMenuEnabled: storeConfig.medicalMenuEnabled,
          saleTypeBasedRoutingEnabled: storeConfig.saleTypeBasedRoutingEnabled,
          routeName: storeConfig.routeName,
          route,
          query,
        });

        await Promise.all([
          await GetShopInfoEndpoint.preload(
            undefined,
            {
              headers: {
                StoreId: storeConfig.id.toString(),
              },
            },
            queryClient,
          ).then((shopInfo) => {
            if (isSSR && shopInfo?.deliveryZones) {
              // eslint-disable-next-line no-param-reassign
              shopInfo.deliveryZones = [];
            }
          }),
          preload?.(
            { headers: { [XSweedHeader.StoreId]: String(storeConfig.id) }, query, queryClient },
            {
              saleType,
              platformOs,
            },
          ),
        ]);

        return {
          mainLayoutProps: getMainLayoutProps?.(),
          meta: getMeta?.({
            queryClient,
            query,
            platformOs,
            saleType,
            intl,
            dealerName: storeConfig.dealerName,
            pathname: route,
          }),
        };
      },
    };
  },
  index,
});

const getRedirects = ({ isTruncatedShop }: Pick<ShopConfig, 'isTruncatedShop'>): RouteObject[] => {
  const redirects = isTruncatedShop ? TRUNCATED_SHOP_REDIRECTS : REDIRECTS;
  return redirects.map(({ source, destination, permanent, queryMapper }) => ({
    path: convertSharedToReactRoute(source),
    loader: async ({ params, request }) => {
      const url = new URL(request.url);
      const _query: RouteQuery = convertReactToSharedQuery(params, url.searchParams);
      const query: RouteQuery = queryMapper ? await queryMapper(_query) : _query;
      if ('all' in query && Array.isArray(query.all)) {
        query['*'] = query.all.join('/');
        delete query.all;
      }
      return redirect(generatePath(convertSharedToReactRoute(destination), query), permanent ? 308 : 307);
    },
    index: source === Routes.Home,
  }));
};

const createRoutesDef = (storeConfig: StoreRouterConfig, globalQueryClient?: QueryClient): RouteObject[] => {
  const intl = new IntlCore({
    language: defaultLocale,
    languages: defaultLanguages,
    dictionary: { [defaultLocale]: storeConfig.translations },
  });
  const _pages: RouteObject[] = Object.values(Routes).map((route) =>
    createMigratedRoute(route, route === Routes.Home, storeConfig, globalQueryClient, intl),
  );

  const pages: RouteObject[] = [
    ...getRedirects(storeConfig),
    ..._pages,
    {
      path: `/*`,
      loader: async ({ context }) => {
        const queryClient = context?.queryClient ?? globalQueryClient;
        const shopInfo = await GetShopInfoEndpoint.preload(
          undefined,
          {
            headers: {
              StoreId: storeConfig.id.toString(),
            },
          },
          queryClient,
        );
        if (isSSR && shopInfo && shopInfo.deliveryZones) {
          shopInfo.deliveryZones = [];
        }
        throw new Response('Not Found', { status: 404 });
      },
    },
  ];

  return [
    {
      element: (
        <ErrorBoundary
          level="fatal"
          onError={logger.reactError}
          fallback={<RootErrorPage />}
        >
          <AppLayout pages={pages} />
        </ErrorBoundary>
      ),
      children: [
        {
          ErrorBoundary: ErrorPage,
          children: pages,
        },
      ],
    },
  ];
};

export { createRoutesDef };
