import { asyncNoop, noop } from '@swe/shared/utils/func';

import { createContext, useContext } from 'react';

import {
  CartActions,
  CartItemsWithAvailability,
  CartMeta,
  CartPrice,
  CartStashActions,
  CartState,
  CartUtils,
} from 'common/providers/cart/types';
import { UseResidencyType } from 'common/use-cases/use-residency';
import { Cart } from 'entities/cart/cart';
import { PurchaseLimit } from 'entities/common/orders';

const CartContext = createContext<(Cart & { biotrackFloridaLimits: PurchaseLimit[] }) | undefined>(undefined);
const useCart = () => useContext(CartContext);

const CartActionsContext = createContext<CartActions>({
  addToCart: asyncNoop,
  removeFromCart: noop,
});
const useCartActions = () => useContext(CartActionsContext);

const CartUtilsContext = createContext<CartUtils>({
  mutate: asyncNoop,
  update: asyncNoop,
  setLocalAvailableItemsQuantity: noop,
  setSkipNextCartUpdate: noop,
});
const useCartUtils = () => useContext(CartUtilsContext);

const CartStateContext = createContext<CartState>({
  isLoading: false,
  isLoadingUpdate: false,
  isValidating: false,
  isLoadingAdd: false,
  isLoadingRemove: false,
});
const useCartState = () => useContext(CartStateContext);

const CartMetaContext = createContext<CartMeta>({
  isVirtual: false,
  isEmpty: false,
  hasMedicalProducts: false,
  isOutOfLimit: false,
});
const useCartMeta = () => useContext(CartMetaContext);

const CartItemsWithAvailabilityContext = createContext<CartItemsWithAvailability>({
  availableItems: [],
  unavailableItems: [],
});
const useCartItemsWithAvailability = () => useContext<CartItemsWithAvailability>(CartItemsWithAvailabilityContext);

const CartPriceContext = createContext<CartPrice>({
  total: 0,
  totalBeforePromo: 0,
});
const useCartPrice = () => useContext(CartPriceContext);

const CartQuantityContext = createContext<number>(0);
const useCartQuantity = () => useContext(CartQuantityContext);

const CartResidencyContext = createContext<UseResidencyType>({
  isResident: false,
  updateResident: asyncNoop,
  isLoading: false,
  setIsResident: noop,
});
const useCartResidency = () => useContext(CartResidencyContext);

const CartStashContext = createContext<CartStashActions>({
  addToStash: noop,
  removeFromStash: noop,
  getStashQuantity: () => 0,
});
const useCartStash = () => useContext(CartStashContext);

export {
  CartContext,
  useCart,
  CartActionsContext,
  useCartActions,
  CartUtilsContext,
  useCartUtils,
  CartStateContext,
  useCartState,
  CartMetaContext,
  useCartMeta,
  CartItemsWithAvailabilityContext,
  useCartItemsWithAvailability,
  CartPriceContext,
  useCartPrice,
  CartQuantityContext,
  useCartQuantity,
  CartResidencyContext,
  useCartResidency,
  CartStashContext,
  useCartStash,
};
