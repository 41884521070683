import { useGeolocationContext } from '@swe/shared/providers/geolocation';

import { useGeocodingService } from '@swe/shared/use-cases/use-address';
import { useCallback, useEffect, useState } from 'react';

import { useDeliveryByLocation } from 'common/containers/header/containers/sub-header/use-cases/use-delivery-by-location';
import { useNavigateToStore } from 'common/containers/header/containers/sub-header/use-cases/use-navigate-to-store';
import { usePickupDeliverySettings } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery-settings';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { FullShopFulfillmentType, LocationCoords } from 'entities/shop/info';

type UseDeliveryArguments = {
  isEnabled?: boolean;
  selectedStoreId?: number;
};

const useDelivery = ({ isEnabled = true, selectedStoreId }: UseDeliveryArguments) => {
  const {
    currentStoreId,
    deliveryAddress: storedDeliveryAddress,
    deliveryAddressSecondLine: storedDeliveryAddressSecondLine,
    deliveryLocation: storedDeliveryLocation,
    saveFulfillmentPreferences,
  } = usePickupDeliverySettings();

  const [deliveryAddress, _setSelectedDeliveryAddress] = useState(storedDeliveryAddress ?? '');
  const [deliveryAddressSecondLine, _setDeliveryAddressSecondLine] = useState(storedDeliveryAddressSecondLine ?? '');
  const [deliveryLocation, _setSelectedDeliveryLocation] = useState<LocationCoords | undefined>(storedDeliveryLocation);
  const { position: realLocation, address: realAddress } = useGeolocationContext();
  const { service, load: loadGeocodingLibrary, isReady: isGeocodingServiceReady } = useGeocodingService();

  const {
    deliveryStore,
    deliveryZone,
    availableStores,
    isLoading: isDeliveryLoading,
  } = useDeliveryByLocation({
    location: deliveryLocation,
    address: deliveryAddress,
    addressExtra: deliveryAddressSecondLine,
    storeId: selectedStoreId,
  });

  const { navigate } = useNavigateToStore(deliveryStore);
  const { platformOs } = usePlatformOs();

  const save = useCallback(async () => {
    if (!deliveryStore) return;
    await saveFulfillmentPreferences({
      fulfillmentType: FullShopFulfillmentType.Delivery,
      deliverySecondaryAddress: deliveryAddressSecondLine,
      deliveryAddress,
      deliveryLatitude: deliveryLocation?.lat,
      deliveryLongitude: deliveryLocation?.lng,
      preferencesStoreId: parseInt(String(deliveryStore.id), 10),
      platformOs,
    });

    if (deliveryStore.id !== currentStoreId) {
      await navigate();
    }
  }, [
    currentStoreId,
    deliveryAddress,
    deliveryAddressSecondLine,
    deliveryLocation?.lat,
    deliveryLocation?.lng,
    deliveryStore,
    navigate,
    platformOs,
    saveFulfillmentPreferences,
  ]);
  const clear = useCallback(() => {
    _setSelectedDeliveryAddress(storedDeliveryAddress ?? '');
    _setDeliveryAddressSecondLine(storedDeliveryAddressSecondLine ?? '');
    _setSelectedDeliveryLocation(storedDeliveryLocation ?? undefined);
  }, [storedDeliveryAddress, storedDeliveryAddressSecondLine, storedDeliveryLocation]);

  const setSelectedDeliveryAddress = useCallback(
    async (address: string) => {
      const location = await service.current?.getLocationByAddress(address);
      _setSelectedDeliveryAddress(address);
      _setSelectedDeliveryLocation(location);

      return {
        address,
        location: location!,
      };
    },
    [service],
  );
  const setSelectedDeliveryLocation = useCallback(
    async (location: LocationCoords) => {
      const address = await service.current?.getAddressByLocation(location);
      _setSelectedDeliveryLocation(location);
      _setSelectedDeliveryAddress(address!);

      return {
        address: address!,
        location,
      };
    },
    [service],
  );
  const setRealLocationAsDelivery = useCallback(
    async (location: google.maps.LatLngLiteral) => {
      const address = await service.current?.getAddressByLocation(location);
      if (address) {
        _setSelectedDeliveryAddress(address);
        _setSelectedDeliveryLocation(location);
        _setDeliveryAddressSecondLine('');

        return {
          address: realAddress,
          location,
        };
      }
    },
    [realAddress, service],
  );

  useEffect(() => {
    if (isEnabled && !isGeocodingServiceReady) {
      void loadGeocodingLibrary();
    }
  }, [isEnabled, isGeocodingServiceReady, loadGeocodingLibrary]);

  return {
    deliveryAddress,
    deliveryAddressSecondLine,
    deliveryLocation,
    deliveryStore,
    deliveryZone,
    availableStores,
    realLocation,
    isDeliveryLoading,
    setSelectedDeliveryAddress,
    setSelectedDeliveryLocation,
    setRealLocationAsDelivery,
    setDeliveryAddressSecondLine: _setDeliveryAddressSecondLine,
    save,
    clear,
  };
};

export { useDelivery };
