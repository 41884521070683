import { BadgeProps } from '@swe/shared/ui-kit/components/badge';

import { OrderProcessStatus } from 'entities/common/orders';
import { isConfirmation, isDelivering, isCanceled, isDone, isSuccessDone } from 'entities/profile/orders';

const matchOrderStatusToType = (
  status: OrderProcessStatus,
  { delivering, preparing, other }: { delivering: string; preparing: string; other: string },
) => {
  switch (true) {
    case isDelivering(status):
      return delivering;
    case isConfirmation(status):
      return preparing;
    default:
      return other;
  }
};

const matchActiveOrderStatusToType = (
  status: OrderProcessStatus,
  { active, finished }: { active: string; finished: string },
) => {
  switch (true) {
    case isCanceled(status) || isDone(status) || isSuccessDone(status):
      return finished;
    default:
      return active;
  }
};

const getBadgeColor = (status: OrderProcessStatus): BadgeProps['color'] =>
  ({
    [OrderProcessStatus.Cancelled]: 'danger' as const,
    [OrderProcessStatus.PickedUp]: 'neutral' as const,
    [OrderProcessStatus.OutForDelivery]: 'success' as const,
    [OrderProcessStatus.OnTheWay]: 'success' as const,
    [OrderProcessStatus.CancelledByTimeout]: 'danger' as const,
    [OrderProcessStatus.Packing]: 'warning' as const,
    [OrderProcessStatus.Delivered]: 'neutral' as const,
    [OrderProcessStatus.Packed]: 'neutral' as const,
    [OrderProcessStatus.Confirmed]: 'warning' as const,
    [OrderProcessStatus.ReadyForPickUp]: 'success' as const,
    [OrderProcessStatus.DocumentVerification]: 'warning' as const,
    [OrderProcessStatus.ActionRequired]: 'warning' as const,
    [OrderProcessStatus.Confirming]: 'warning' as const,
  })[status] as BadgeProps['color'];

export { matchOrderStatusToType, matchActiveOrderStatusToType, getBadgeColor };
