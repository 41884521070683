import { clearKeyFromPrefix, isOurKey, prefixKey, Storage } from '@swe/shared/providers/persist-state/constants';
import { Serializable } from '@swe/shared/types/json';
import { isSSR } from '@swe/shared/utils/environment';
import { isEqual, jsonParseSafe } from '@swe/shared/utils/object';

class BrowserStorage extends Storage {
  private storage: globalThis.Storage | undefined;

  constructor(storageType: 'local' | 'session' = 'local') {
    if (isSSR) {
      // eslint-disable-next-line no-constructor-return
      return new Storage() as BrowserStorage;
    }
    super();
    this.storage = storageType === 'local' ? window.localStorage : window.sessionStorage;
    window.addEventListener('storage', ({ key, oldValue, newValue, storageArea }) => {
      if (key && isOurKey(key) && storageArea === this.storage && oldValue !== newValue) {
        this.fire('change', {
          key: clearKeyFromPrefix(key),
          newValue: jsonParseSafe(newValue),
          oldValue: jsonParseSafe(oldValue),
        });
      }
    });
  }

  getItem<V extends Serializable = Serializable>(key: string) {
    return jsonParseSafe(this.storage!.getItem(prefixKey(key))) as V | null;
  }

  setItem<V extends Serializable = Serializable>(key: string, newValue: V | null) {
    const oldValue = this.getItem(key);
    if (isEqual(oldValue, newValue)) {
      return;
    }
    if (newValue === null) {
      this.storage!.removeItem(prefixKey(key));
    } else {
      this.storage!.setItem(prefixKey(key), JSON.stringify(newValue));
    }
    this.fire('change', { key, newValue, oldValue });
  }
}

export default BrowserStorage;
