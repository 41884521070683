import { useIsPWA } from '@swe/shared/tools/media';
import { Portal } from '@swe/shared/ui-kit/components/portal';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { createContext, useContext, useMemo } from 'react';

import { InstallPwaBanner } from 'common/providers/pwa/components/install-pwa-banner';
import { usePushNotifications } from 'common/use-cases/pwa/use-push-notifications';
import { useInstallPwa } from 'common/use-cases/use-install-pwa';

type ContextType = {
  push: ReturnType<typeof usePushNotifications>;
  install: ReturnType<typeof useInstallPwa>;
  isInStandaloneMode: boolean;
};

const Context = createContext<ContextType>({
  push: null!,
  install: null!,
  isInStandaloneMode: false,
});

const PWAProvider = ({ children, isOpera }: ComponentHasChildren & { isOpera: boolean }) => {
  const push = usePushNotifications();
  const install = useInstallPwa({ isOpera });

  const isInStandaloneMode = useIsPWA();

  const ctx = useMemo(() => ({ push, install, isInStandaloneMode }), [push, install, isInStandaloneMode]);

  return (
    <Context.Provider value={ctx}>
      {children}
      {install.isBannerVisible && (
        <Portal name="fixedFooter">
          <InstallPwaBanner
            onInstall={install.install}
            onClose={install.closeBanner}
          />
        </Portal>
      )}
    </Context.Provider>
  );
};

const usePWA = () => useContext(Context);

export { PWAProvider, usePWA };
