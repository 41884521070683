import { IS_DEV } from '@swe/shared/tools/env';

class ConsoleLogger {
  constructor(private readonly prefixTemplate = '[App|{{level}}]:') {}

  private preparePrefix(level: string) {
    return this.prefixTemplate.replace('{{level}}', level);
  }

  private prepareMessage(message: string, level: string) {
    return `${this.preparePrefix(level)} ${message}`;
  }

  info(message: string) {
    console.info(this.prepareMessage(message, 'Info'));
  }

  log(message: string, ...args: any[]) {
    if (!IS_DEV) return;
    // eslint-disable-next-line no-console
    console.log(this.prepareMessage(message, 'Log'), ...args);
  }

  forceLog(message: string, ...args: any[]) {
    // eslint-disable-next-line no-console
    console.log(this.prepareMessage(message, 'Log'), ...args);
  }

  error(message: string, error: any) {
    if (!IS_DEV) return;
    console.error(this.prepareMessage(message, 'Error'), error);
  }
}

const consoleLogger = new ConsoleLogger();

export { ConsoleLogger, consoleLogger };
