import { useBreakpoint } from '@swe/shared/tools/media';
import Button from '@swe/shared/ui-kit/components/button';
import { ChevronLeftIcon } from '@swe/shared/ui-kit/components/icon';
import Modal from '@swe/shared/ui-kit/components/modal';

import { useModalContext } from '@swe/shared/ui-kit/components/modal/context';
import { useNearestScrollable } from '@swe/shared/ui-kit/components/scrollable';

import { useEffect } from 'react';

import { ProductCardListItem } from 'common/containers/product-details-modal/containers/list-item';
import { Reminder } from 'common/containers/reminder';
import { useRouterNavigate } from 'common/router';
import { usePDP, usePDPControls } from 'common/use-cases/use-pdp';
import { AddProductSkeletonized } from 'domains/product/components/product-details/components/add-to-cart';
import { ProductDetails } from 'domains/product/containers/product-details';
import { useProductByVariant } from 'domains/product/use-cases/use-product';
import { GetProductError } from 'endpoints/product/get-product-by-variant-id';
import { getProductName, Product, ProductVariant } from 'entities/product/product';

type DetailsProps = {
  product?: Product;
  variant?: ProductVariant;
  variantId?: ProductVariant['id'];
  error?: GetProductError;
  canGoBack: boolean;
  onVariantSelected: (variant: ProductVariant) => void;
  onProductAdd: () => void;
  onClose: () => void;
  onUpdateReminder: () => void;
};

const Details = ({
  product,
  variant,
  variantId,
  error,
  onVariantSelected,
  onProductAdd,
  canGoBack,
  onClose,
  onUpdateReminder,
}: DetailsProps) => {
  const { scroll } = useNearestScrollable();
  const { setHeaderOffsetBlock } = useModalContext();
  const { hasPrevious } = usePDP();

  useEffect(() => {
    if (hasPrevious && product) {
      scroll({ top: 0, smooth: false });
    }
    if (product) {
      window.performance.mark('open-pdp-end');
      // console.log(window.performance.measure('open-pdp', 'open-pdp-start', 'open-pdp-end').duration);
    }
  }, [hasPrevious, product, scroll]);

  return (
    <ProductDetails
      product={product}
      variant={variant}
      error={error}
      shallowVariantId={variantId}
      canGoBack={canGoBack}
      isInModal
      setVariant={onVariantSelected}
      setHeaderOffsetBlock={setHeaderOffsetBlock}
      onProductAdd={onProductAdd}
      onClose={onClose}
      onUpdateReminder={onUpdateReminder}
    />
  );
};

const ProductDetailsModal = () => {
  const { isPdpOpened, variantId, hasPrevious } = usePDP();
  const { close } = usePDPControls();
  const navigate = useRouterNavigate();
  const { product, variant, isProductNotFound, setVariant, error, mutate } = useProductByVariant(variantId);
  const { mobile } = useBreakpoint();

  return (
    <Modal
      ariaLabel={product ? getProductName(product) : ''}
      visible={isPdpOpened}
      showCloseButton
      isHeaderEnabled={false}
      size="xxl"
      fillHeight
      renderBackButton={(props) =>
        hasPrevious && (
          <Button
            {...props}
            icon={ChevronLeftIcon}
            color="light"
            onClick={() => navigate(-1)}
            ariaLabel="Back"
          />
        )
      }
      floatingHeader={
        product &&
        !isProductNotFound && (
          <ProductCardListItem
            product={product}
            variant={variant}
            onProductAdded={close}
          />
        )
      }
      footer={
        mobile &&
        !isProductNotFound && (
          <Reminder
            variant={variant}
            onUpdate={() => mutate()}
          >
            <AddProductSkeletonized
              product={product}
              variant={variant}
              onProductAdd={close}
            />
          </Reminder>
        )
      }
      onClose={close}
    >
      <Details
        product={product}
        variant={variant}
        error={error}
        variantId={variantId}
        onVariantSelected={setVariant}
        onProductAdd={close}
        canGoBack={hasPrevious}
        onClose={close}
        onUpdateReminder={mutate}
      />
    </Modal>
  );
};

export type {};
export { ProductDetailsModal };
