import { Button } from '@swe/shared/ui-kit/components/button';
import Scrollable from '@swe/shared/ui-kit/components/scrollable';

import Stack from '@swe/shared/ui-kit/components/stack';

import { useMemo } from 'react';

import { AddToCartProps } from 'common/components/add-to-cart';

type QuantitySuggestionsProps = {
  presets: number[];
  quantity: number;
  onChange?: (nextQuantity: number) => void;
} & Pick<AddToCartProps, 'availableQuantity'>;

const QuantitySuggestions = ({ quantity, availableQuantity, presets, onChange }: QuantitySuggestionsProps) => {
  const quantities = useMemo(() => {
    const unreachableQtyIndex = presets.findIndex((qty) => qty > availableQuantity);
    if (unreachableQtyIndex > -1) {
      return presets.slice(0, unreachableQtyIndex);
    }
    return presets;
  }, [availableQuantity, presets]);

  return quantities.length ? (
    <Scrollable
      direction="horizontal"
      fade
    >
      <Stack
        direction="row"
        spacing="xxxs"
      >
        {quantities.map((qty) => (
          <Button
            name="addSomeGrams"
            size="sm"
            color={quantity === qty ? 'neutral' : 'light'}
            style={{ flex: `0 1 $25%` }}
            onClick={() => onChange?.(qty)}
          >{`${qty} G`}</Button>
        ))}
      </Stack>
    </Scrollable>
  ) : null;
};

export { QuantitySuggestions as GramsSuggestions };
export default QuantitySuggestions;
