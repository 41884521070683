import { PageMeta, PageMetaLink, PageMetaType } from 'app/types';
import { Product, ProductSaleType } from 'entities/product/product';

const getProductImageAlt = (product: Product) => {
  const { brand, name } = product;
  return `${name}${brand?.name ? ` ${brand.name}` : ''}`;
};

const getProductImageTitle = getProductImageAlt;

const getLogoAlt = (title: string) => {
  return `Cannabis store ${title}`;
};

const getCategoryAlt = (categoryName: string) => {
  return `Buy ${categoryName} Cannabis`;
};

const getBannerAlt = (name: string) => {
  return `Cannabis Promo, Cannabis Sales, Cannabis Discounts, Cannabis on Sale, ${name}`;
};

const getSeoSaleTypes = (saleTypes: ProductSaleType[]) =>
  saleTypes.map((type) => (type === 'Recreational' ? 'Personal' : type));

const getSaleTypeMsg = (saleTypes: ProductSaleType[]) =>
  `for ${getSeoSaleTypes(saleTypes).join(' and ').toLowerCase()} use`;

const createPageMetaBuilder = ({ finalize }: { finalize?: () => void } = {}) => {
  const separator = '|';
  const title: (string | undefined)[] = [];
  const description: (string | undefined)[] = [];
  const keywords: (string | undefined)[] = [];
  const links: (PageMetaLink | undefined)[] = [];
  const images: (AbsoluteURL | undefined)[] = [];
  const shortTitle: (string | undefined)[] = [];
  const values: { type?: PageMetaType } = {};

  const filter = <T>(pats: (T | undefined)[]) => pats.filter((part) => !!part) as T[];
  const clear = (entry: string) => {
    let result = entry;
    while (result.includes(' |  | ')) {
      result = result.replace(' |  | ', ' | ');
    }
    return result;
  };

  const arrFn = <T>(arr: (T | undefined)[]) => ({
    add(part: T | undefined | (T | undefined)[]) {
      arr.push(...(Array.isArray(part) ? part : [part]));
      return this;
    },
  });

  const valFn = <N extends keyof typeof values, V extends (typeof values)[N]>(name: N) => ({
    set(val: V) {
      values[name] = val;
      return this;
    },
  });

  return {
    title: arrFn(title),
    description: arrFn(description),
    keywords: arrFn(keywords),
    links: arrFn(links),
    images: arrFn(images),
    shortTitle: arrFn(shortTitle),
    type: valFn('type'),
    toProps: (): PageMeta => {
      finalize?.();

      const filteredShortTitle = filter(shortTitle);

      return {
        title: clear(filter(title).join(` ${separator} `)),
        shortTitle: filteredShortTitle.length > 0 ? filteredShortTitle.join(` ${separator} `) : undefined,
        description: filter(description).join(` ${separator} `),
        keywords: filter(keywords),
        links: filter(links),
        images: filter(images),
        type: values.type,
      };
    },
  };
};

export {
  createPageMetaBuilder,
  getProductImageAlt,
  getProductImageTitle,
  getLogoAlt,
  getCategoryAlt,
  getBannerAlt,
  getSeoSaleTypes,
  getSaleTypeMsg,
};
