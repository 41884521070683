import { consoleLogger } from '@swe/shared/tools/logger/console';

import GetProfileNotificationsEndpoint from 'endpoints/profile/notifications/get-notifications';
import { ToggleMarketingNotificationEndpoint } from 'endpoints/profile/notifications/toggle-marketing-notifications';
import ToggleTransactionNotifications from 'endpoints/profile/notifications/toggle-transaction-notifications';
import { Notification, NotificationMethod, NotificationType } from 'entities/profile/notifications';

const isPushSetting = (n: Notification) => n.notificationMethod === NotificationMethod.Push;
const isSettingEnabled = (n: Notification) => n.enabled;
const enablePushNotification = async (type: NotificationType) => {
  const toggle =
    type === NotificationType.Transactional
      ? ToggleTransactionNotifications.request
      : ToggleMarketingNotificationEndpoint.request;

  try {
    await toggle({
      notificationMethod: NotificationMethod.Push,
      enabled: true,
    });
  } catch (e) {
    consoleLogger.error('Failed to enable push notifications', e);
  }
};

const enableNotifications = async () => {
  try {
    const notifications = await GetProfileNotificationsEndpoint.request(undefined);

    const transactionalPushSetting = notifications.transactions.find(isPushSetting);
    if (transactionalPushSetting && !isSettingEnabled(transactionalPushSetting)) {
      await enablePushNotification(NotificationType.Transactional);
    }

    const marketingPushSetting = notifications.promotions.find(isPushSetting);
    if (marketingPushSetting && !isSettingEnabled(marketingPushSetting)) {
      await enablePushNotification(NotificationType.Marketing);
    }
  } catch (e) {
    consoleLogger.error('Failed to enable notifications!', e);
  }
};

export { enableNotifications, enablePushNotification };
