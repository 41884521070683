import { RoutePath, RouteQuery } from '@swe/shared/providers/router/constants';

import { matchRoutes, Params, RouteObject } from 'react-router-dom';

import { CompilationType } from 'entities/common/compilation';

const convertSharedToReactRoute = (route: RoutePath): RoutePath => {
  return route
    .replace(/\[\[(\w*)\]\]/g, (_, key) => `:${key}?`)
    .replace(/\[(\w*)\]/g, (_, key) => `:${key}`)
    .replace('/[[...all]]', '/*') as RoutePath;
};

const convertReactToSharedRoute = (route: RoutePath): RoutePath => {
  return route
    .replace(/:(\w*)\?/g, (_, key) => `[[${key}]]`)
    .replace(/:(\w*)/g, (_, key) => `[${key}]`)
    .replace('/*', '/[[...all]]') as RoutePath;
};

const convertReactToSharedQuery = ({ '*': all, ..._params }: Params, searchParams: URLSearchParams): RouteQuery => {
  const query: RouteQuery = {
    ...Object.fromEntries(searchParams.entries()),
    ..._params,
  };

  if (all) {
    query.all = all.split('/');
  }

  return query;
};

const getRouteMatch = (routes: RouteObject[], pathname: RoutePath, basePath: RoutePath | undefined) => {
  const matches = matchRoutes(routes, pathname, basePath);
  return matches ? matches[matches.length - 1] : undefined;
};

const getRouteFromMatchPath = <R extends RoutePath = RoutePath>(path: string | undefined, notFoundRoute: R): R => {
  if (path) {
    if (path === '*') {
      return notFoundRoute;
    }
    return convertReactToSharedRoute(path as RoutePath) as R;
  }
  return notFoundRoute;
};

const getRouteFromMatches = <R extends RoutePath = RoutePath>(
  routes: RouteObject[],
  pathname: RoutePath,
  basePath: RoutePath | undefined,
  notFoundRoute: R,
): R => {
  const match = getRouteMatch(routes, pathname, basePath);
  return getRouteFromMatchPath(match?.route.path, notFoundRoute);
};

/* TODO: maybe not common utils */

const STORE_ID_BASE_PATH = /^s(\d+)$/;
const getStoreIdFromBasePath = (basePath?: string) => {
  const [, storeId] = (basePath || '').match(STORE_ID_BASE_PATH) ?? [];
  return storeId ? Number(storeId) : null;
};

const createStoreIdBasePath = (storeId: EntityID) => `s${storeId}`;

const isLikeId = (str?: string) => !!str && /^\d+$/.test(str.trim());

const parseNamedId = <T extends 'number' | 'string' = 'string'>(slug: string, type?: T) => {
  const lastSegment = isLikeId(slug) ? slug : slug.split('-').pop();
  if (lastSegment) {
    const id = lastSegment.trim();
    return (isLikeId(id) ? (type === 'number' ? (Number(id) as EntityID<number>) : (id as EntityID<string>)) : null) as
      | (T extends 'number' ? EntityID<number> : EntityID<string>)
      | null;
  }
  return null;
};

const isLikeCarousel = (name: string) => (name || '').split('-')[0] === CompilationType.CAROUSEL;

const buildNamedId = (_name: string | string[] | undefined, id: EntityID) => {
  const name = Array.isArray(_name) ? [..._name, id] : [_name, id];

  return encodeURIComponent(
    name
      .filter(Boolean)
      .map((str) =>
        String(str)
          .toLowerCase()
          .replace(/[^A-Za-z0-9\-_.!~*'()]/g, ' ')
          .trim(),
      )
      .join('-')
      .replace(/\s+/g, '-'),
  );
};

const buildCarouselNamedId = (name: string, id: EntityID) => buildNamedId([CompilationType.CAROUSEL, name], id);

export {
  convertSharedToReactRoute,
  convertReactToSharedRoute,
  getRouteFromMatches,
  convertReactToSharedQuery,
  getStoreIdFromBasePath,
  createStoreIdBasePath,
  parseNamedId,
  isLikeCarousel,
  buildNamedId,
  buildCarouselNamedId,
  getRouteMatch,
  getRouteFromMatchPath,
};
