import { useMounted } from '@swe/shared/hooks/use-mounted';

import { usePostHog } from 'posthog-js/react';

import { useEffect, useMemo } from 'react';

import type { AppRuntimeConfig } from '@swe/shop-ui/runtime-config';
import { AEventType, AnalyticsAdapter } from 'common/providers/analytics/constants';
import { useCurrentUser } from 'common/providers/user';

const IS_DEBUG = process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true';

enum EventType {
  PAGE_VIEW = '$pageview',
  BANNER_CLICK = 'banner_click',
}

const EVENT_TYPE_MAP: Partial<Record<AEventType, EventType>> = {
  [AEventType.PAGE_VIEW]: EventType.PAGE_VIEW,
  [AEventType.BANNER_CLICK]: EventType.BANNER_CLICK,
};

type PosthogConfig = AppRuntimeConfig['posthogConfig'];

const usePosthogAdapter = (config?: PosthogConfig) => {
  const { user } = useCurrentUser();
  const posthog = usePostHog();

  useMounted(() => {
    if (config?.enabled && config?.key) {
      posthog.init(config.key, {
        api_host: config.host,
        loaded: IS_DEBUG ? (p) => p.debug() : undefined,
      });
    }
  });

  useEffect(() => {
    if (user) {
      const accountId = String(user.accountId);
      posthog.identify(accountId);

      return () => {
        posthog.reset();
      };
    }
  }, [posthog, user]);

  return useMemo<AnalyticsAdapter>(
    () => ({
      pushEvent: (type, payload, meta) => {
        posthog.capture(EVENT_TYPE_MAP[type] ?? type, {
          ...payload,
          ...meta,
        });
      },
    }),
    [posthog],
  );
};

export { usePosthogAdapter };
export type { PosthogConfig };
