import { Route, RoutePath, RouteQuery, RouteUrl } from '@swe/shared/providers/router/constants';
import { omitBy } from '@swe/shared/utils/object';

const ABSOLUTE_URL_REGEXP = /^(?:[a-z+]+:)?\/\//i;
const isAbsoluteURL = (url: string) => ABSOLUTE_URL_REGEXP.test(url);
const isUtilsURL = (url: string) => ['tel:', 'mailto:'].some((prefix) => url.startsWith(prefix));
const isExternalURL = (url: RouteUrl<any>) => url.basePath && url.basePath.startsWith('http');

const queryToURLSearchParams = (query: Record<any, any>) => {
  const q = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        q.append(key, String(v));
      });
      return;
    }
    q.append(key, String(value));
  });
  return q;
};

const stringifyRouteUrl = <R extends RoutePath>(
  { pathname, query: _query, basePath }: Required<RouteUrl<R>>,
  ignoreBasePath = false,
): RoutePath => {
  let urlStr: string = pathname;
  const query = Object.entries(_query).reduce((acc, [key, value]) => {
    if (value) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {} as any);
  urlStr = urlStr
    .replace(/\[\[(\w*)\]\]/g, (_, key: string) => {
      const value = query[key];
      if (value) {
        delete query[key];
        return value;
      }
      return '';
    })
    .replace('//', '/');
  urlStr = urlStr.replace(/\[(\w*)\]/g, (_, key: string) => {
    const value = query[key];
    if (value) {
      delete query[key];
      return value;
    }
    return `[${key}]`;
  });
  urlStr = urlStr.replace(/\[\[\.\.\.(\w*)\]\]/g, (_, key) => {
    const value = query[key];
    if (Array.isArray(value)) {
      delete query[key];
      return value.join('/');
    }
    return ``;
  });
  if (Object.entries(query).length > 0) {
    urlStr = `${urlStr}?${queryToURLSearchParams(query)}`;
  }

  return `${!ignoreBasePath && basePath && basePath !== '/' ? basePath : ''}${urlStr}` as RoutePath;
};

const normalizeRoute = <R extends RoutePath>(
  route: Route<R>,
  currentPathname: R,
  currentBasePath: RoutePath,
  defaultQuery: RouteQuery = {},
): Required<RouteUrl<R>> => {
  if (typeof route === 'object') {
    const { query: _query, pathname, basePath } = route;
    const query = {
      ...defaultQuery,
      ..._query,
    };
    return {
      pathname: pathname ?? currentPathname,
      query: omitBy(query, (val) => val === undefined),
      basePath: basePath ?? currentBasePath,
    };
  }

  return {
    pathname: route,
    basePath: currentBasePath,
    query: omitBy(defaultQuery, (val) => val === undefined),
  };
};

export { normalizeRoute, stringifyRouteUrl, isAbsoluteURL, isUtilsURL, isExternalURL };
