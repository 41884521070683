import Button, { ButtonSize, ButtonColor } from '@swe/shared/ui-kit/components/button';
import { CartAddIcon, MinusIcon, PlusIcon, TrashIcon } from '@swe/shared/ui-kit/components/icon';
import Price from '@swe/shared/ui-kit/components/price';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cn from 'clsx';

import styles from './styles.module.scss';

type AddToCartProps = ComponentHasClassName & {
  filled?: boolean;
  price?: Price$;
  quantity?: number;
  minQuantity?: number;
  availableQuantity: number;
  onAdd(): void;
  onRemove(): void;
  size?: ButtonSize;
  color?: ButtonColor;
  counterOnly?: boolean;
  lastItemIconTrash?: boolean;
  preventClick?: boolean;
  lockLastItem?: boolean;
};

const AddToCart = ({
  quantity = 0,
  availableQuantity,
  price,
  minQuantity,
  counterOnly,
  className,
  onAdd,
  onRemove,
  size,
  filled,
  color,
  lastItemIconTrash,
  preventClick = false,
  lockLastItem = false,
}: AddToCartProps) => {
  const isAdded = !!(quantity && quantity > 0);
  const showSubButtons = counterOnly ? true : isAdded || !filled;
  const removeIcon = lastItemIconTrash && quantity === 1 ? TrashIcon : MinusIcon;
  const addIcon = lastItemIconTrash && !isAdded ? CartAddIcon : PlusIcon;

  const handleAdd = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (preventClick) {
      e.preventDefault();
      e.stopPropagation();
    }
    onAdd();
  };
  const handleRemove = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (preventClick) {
      e.preventDefault();
      e.stopPropagation();
    }
    onRemove();
  };

  return (
    <div
      className={cn(styles.root, className, { [styles._filled]: isAdded || filled })}
      onClick={(e) => e.preventDefault()}
    >
      <Button
        className={styles.rootBtn}
        size={size}
        color={color}
        onClick={showSubButtons ? undefined : handleAdd}
        hoverable={!showSubButtons}
        disabled={!showSubButtons && availableQuantity <= 0}
        ariaLabel="Add to Cart"
        tabIndex={isAdded ? -1 : 0}
      >
        {showSubButtons ? (
          <>
            {quantity}
            {price && (
              <>
                {' '}
                • <Price value={price} />
              </>
            )}
          </>
        ) : availableQuantity > 0 ? (
          <>Add to Cart • {price && <Price value={price} />}</>
        ) : (
          'Out of stock'
        )}
      </Button>
      {showSubButtons && (
        <>
          <Button
            size={size}
            color={color}
            type="button"
            className={styles.removeBtn}
            disabled={quantity === minQuantity || (quantity === 1 && lockLastItem)}
            tabIndex={isAdded ? 0 : -1}
            onClick={handleRemove}
            icon={removeIcon}
            ariaLabel="Remove from Cart"
            border="none"
          />
          <Button
            size={size}
            color={color}
            type="button"
            className={styles.addBtn}
            icon={addIcon}
            onClick={handleAdd}
            disabled={quantity >= availableQuantity}
            tabIndex={isAdded ? 0 : -1}
            ariaLabel="Add to Cart"
            border="none"
          />
        </>
      )}
    </div>
  );
};

export type { AddToCartProps };
export { AddToCart };
