import GetLabDataEndpoint from 'endpoints/product/get-lab-data';
import { LaboratoryDataComponent, ProductLaboratoryData, ProductVariant } from 'entities/product/product';

const isComponentEmpty = (c: LaboratoryDataComponent) => c.values.length === 0 && !c.unitAbbr;

const isEmpty = (data?: ProductLaboratoryData) => {
  if (!data) return false;

  return Object.values(data).every(isComponentEmpty);
};

const useLabData = (variantId?: ProductVariant['id'], hasLabData?: boolean) => {
  const { data, isLoading, error, mutate } = GetLabDataEndpoint.useRequest(
    variantId && hasLabData ? { variantId } : null,
  );

  return {
    labData: isEmpty(data) ? undefined : data,
    isLoading,
    error,
    mutate,
  };
};

export { useLabData };
