import { ScheduleInterval, SimplifiedSchedule } from 'entities/common/schedule';
import { ProductFiltersQuery } from 'entities/product/filter';

export enum DicsountFulfillmentType {
  InStorePickup = 'In-store',
  Pickup = 'Pickup',
  Delivery = 'Delivery',
}

export type Discount = NamedEntity & {
  shortName: string;
  description?: string;
  isBogo?: boolean;
  displayInCatalog: boolean;
  displayInDiscountMenu: boolean;
  endDate?: DateISOString;
  bannerUrl: Image;
  mobileBannerUrl?: Image;
  productImages?: Image[];
  filter: ProductFiltersQuery;
  interval?: ScheduleInterval;
  isConditional?: boolean;
  isActiveBySchedule?: boolean;
  scheduleDisplayText?: SimplifiedSchedule;
  fulfilmentTypes?: DicsountFulfillmentType[];
};

export enum InactivityReason {
  NOT_ACTIVE = 'NotActive',
  ANOTHER_TIME = 'AnotherTime',
  OUT_OF_STOCK = 'OutOfStock',
  NOT_AVAILABLE_FOR_USER = 'NotAvailableForUser',
  NOT_AUTHORIZED_USER = 'NotAuthorizedUser',
  BAD_SALE_TYPE = 'BadSaleType',
}
