import { Datatable } from '@swe/shared/ui-kit/components/datatable';
import { Column } from '@swe/shared/ui-kit/components/datatable/types';
import { InfoIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import { Tooltip } from '@swe/shared/ui-kit/components/tooltip';

import { ReactNode } from 'react';

import styles from './styles.module.scss';

import { LaboratoryDataComponentUnit, LaboratoryDataComponentValue } from 'entities/product/product';

enum DataSourceKey {
  Name = 'name',
  Value = 'value',
}

type Source = Record<DataSourceKey, string>;
type TableColumn = Column<Source>;

const mapRawDataToDataSource = (values: LaboratoryDataComponentValue[]) => {
  return values.map((ldcv) => ({
    [DataSourceKey.Name]: ldcv.name,
    [DataSourceKey.Value]:
      ldcv.min && ldcv.max && ldcv.min !== ldcv.max ? `${ldcv.min} - ${ldcv.max}` : (ldcv.max ?? ldcv.min).toString(),
  }));
};

const getColumns = (unitAbbr: LaboratoryDataComponentUnit | null, heading?: ReactNode): TableColumn[] => [
  { name: DataSourceKey.Name, heading: unitAbbr ? heading ?? 'Per package' : '' },
  { name: DataSourceKey.Value, heading: unitAbbr ?? '', hAlign: 'right' },
];

type LaboratoryDataBlockProps = {
  data: LaboratoryDataComponentValue[];
  unitAbbr: LaboratoryDataComponentUnit | null;
  heading?: ReactNode;
  header?: false;
};

const LaboratoryDataBlock = ({ heading, header, data, unitAbbr }: LaboratoryDataBlockProps) => {
  const source = mapRawDataToDataSource(data);
  const columns = getColumns(unitAbbr);

  return (
    <div>
      {header !== false && <SectionHeading>{heading}</SectionHeading>}
      <Datatable
        source={source}
        columns={columns}
      />
    </div>
  );
};

const LaboratoryDataPerServingBlock = ({ data, unitAbbr }: Omit<LaboratoryDataBlockProps, 'header' | 'heading'>) => {
  const heading = (
    <div className={styles.heading}>
      <div className={styles.text}>Per serving</div>
      <Tooltip content="Serving size is a recommended amount of the product THC that should be consumed in a single sitting">
        <InfoIcon />
      </Tooltip>
    </div>
  );
  const source = mapRawDataToDataSource(data);
  const columns = getColumns(unitAbbr, heading);

  return (
    <Datatable
      source={source}
      columns={columns}
    />
  );
};

export { LaboratoryDataBlock, LaboratoryDataPerServingBlock };
