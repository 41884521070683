import { ExtendToViewMode } from '@swe/shared/ui-kit/components/container/utils/use-extend-el-to-container';
import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';

import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { CarouselProductsSkeletonized } from 'domains/shop/components/carousel-products';
import GetAlsoBoughtWithEndpoint from 'endpoints/product/get-also-bought-with-list';
import GetListEndpoint from 'endpoints/product/get-list';
import { CommonFilters } from 'entities/product/filter';
import { Product, ProductVariant } from 'entities/product/product';

type AdditionalProductsCarouselProps = ComponentHasClassName & {
  variantIds?: ProductVariant['id'][];
  title?: string;
  keepPrevious?: boolean;
  extendToView?: ExtendToViewMode;
};

const filterByVariant = (products: Product[], ids: ProductVariant['id'][] = []) =>
  products.filter((p) => p.variants.every((v) => !ids.map(String).includes(String(v.id))));

const AdditionalProductsCarousel = forwardRef<HTMLDivElement, AdditionalProductsCarouselProps>(
  ({ className, variantIds = [], title, keepPrevious = false, extendToView }, ref) => {
    const [isFirstCarouselHidden, setFirstCarouselHidden] = useState(false);
    const { toCatalog } = useCatalogQuery();
    const isParamsEmpty = variantIds?.length <= 0;
    const { saleType } = useSaleType();
    const { platformOs } = usePlatformOs();
    const {
      data: alsoBoughtWithList,
      isValidating: isAlsoBoughtCarouselValidating,
      isLoading: isAlsoBoughtCarouselLoading,
      error: alsoBoughtWithError,
    } = GetAlsoBoughtWithEndpoint.useRequest(
      variantIds?.length > 0
        ? {
            saleType,
            variantIds,
            productIds: [],
            platformOs,
          }
        : null,
      undefined,
      { keepPreviousData: keepPrevious },
    );
    const {
      data: popularList,
      isLoading: isPopularLoading,
      error: popularError,
    } = GetListEndpoint.useRequest(
      {
        saleType,
        filters: CommonFilters.POPULAR,
        page: 1,
        pageSize: 10,
        platformOs,
      },
      undefined,
      { keepPreviousData: keepPrevious },
    );

    const isLoading = isAlsoBoughtCarouselLoading || isPopularLoading;

    const initialVariantIds = useRef(variantIds);
    if (initialVariantIds.current.length === 0 && variantIds?.length > 0) {
      initialVariantIds.current = variantIds;
    }

    const filteredData = useMemo(() => {
      return {
        fbt: filterByVariant(alsoBoughtWithList ?? [], initialVariantIds.current),
        popular: filterByVariant(popularList?.list ?? [], initialVariantIds.current),
      };
    }, [alsoBoughtWithList, popularList?.list]);

    useEffect(() => {
      if (!(isAlsoBoughtCarouselLoading || isAlsoBoughtCarouselValidating) && isParamsEmpty) {
        setFirstCarouselHidden(true);
      } else {
        setFirstCarouselHidden(false);
      }
    }, [isAlsoBoughtCarouselLoading, isAlsoBoughtCarouselValidating, isParamsEmpty]);

    if (!isLoading && filteredData.fbt.length === 0 && filteredData.popular.length === 0) return null;

    return (
      <Stack
        ref={ref}
        className={className}
        spacing="xl"
      >
        {!isFirstCarouselHidden && !alsoBoughtWithError && (
          <CarouselProductsSkeletonized
            name={title ?? 'Frequently bought together'}
            products={alsoBoughtWithList ? filteredData.fbt : undefined}
            extendToView={extendToView}
            id="frequently_bought_together"
          />
        )}
        {!popularError && (
          <CarouselProductsSkeletonized
            name="Popular"
            onViewAll={() => toCatalog({ filters: CommonFilters.POPULAR, subPath: null, searchTerm: null })}
            total={popularList?.total}
            products={popularList ? filteredData.popular : undefined}
            extendToView={extendToView}
            id="popular"
          />
        )}
      </Stack>
    );
  },
);

export { AdditionalProductsCarousel };
export default AdditionalProductsCarousel;
