import { ReactNode, useEffect } from 'react';

import { Route, RoutePath } from '@swe/shared/providers/router/constants';
import { useRouterNavigate, useRouterPathname } from '@swe/shared/providers/router/index';
import { isSSR } from '@swe/shared/utils/environment';

const useRedirect = <R extends RoutePath>(to: Route<R>, condition: () => boolean) => {
  const navigate = useRouterNavigate<R>();
  useEffect(() => {
    if (!isSSR && condition()) {
      void navigate(to, { replace: true });
    }
  }, [condition, navigate, to]);
};

type RedirectProps<R extends RoutePath> = {
  to: Route<R>;
  render?: ReactNode;
};

const truPred = () => true;

const Redirect = <R extends RoutePath>({ to, render = null }: RedirectProps<R>) => {
  useRedirect<R>(to, truPred);
  return render as any;
};

const isMatchRouter = <R extends RoutePath>(route: R | R[], pathname: R) => {
  const routes = Array.isArray(route) ? route : [route];
  return routes.some((route) => pathname.startsWith(route));
};

const useMatchRoute = <R extends RoutePath>(route: R | R[]) => {
  const pathname = useRouterPathname<R>();
  return isMatchRouter<R>(route, pathname);
};

export { useRedirect, useMatchRoute, Redirect, isMatchRouter };
