import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import { GramsSuggestions } from './quantity-suggestions';
import { QuantitySuggestionsSkeleton } from './skeleton';

import { useAddProductToCart } from 'common/use-cases/use-add-product-to-cart';
import { Product, ProductVariant } from 'entities/product/product';

type QuantitySuggestionsProps = {
  product: Product;
  variant: ProductVariant;
  presets: number[];
};

const QuantitySuggestions = ({ product, variant, presets }: QuantitySuggestionsProps) => {
  const {
    quantity: realQuantity,
    stashQuantity,
    availableQuantity,
    updateStash,
  } = useAddProductToCart(product, variant);

  const quantity = stashQuantity ?? (realQuantity === 0 ? 1 : realQuantity);

  return (
    <GramsSuggestions
      quantity={quantity}
      availableQuantity={availableQuantity}
      presets={presets}
      onChange={updateStash}
    />
  );
};

const QuantitySuggestionsSkeletonized = withSkeleton(
  QuantitySuggestions,
  QuantitySuggestionsSkeleton,
  ['product', 'variant'],
  true,
);

export type { QuantitySuggestionsProps };
export { QuantitySuggestions, QuantitySuggestionsSkeletonized };
export default QuantitySuggestions;
