import Price from '@swe/shared/ui-kit/components/price';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

// The variable name 'isAddToCartPopup' should be used for search in the project.
// After the UI/UX designers finalize the tokens, this variable needs to be removed.
type ProductPriceProps = ComponentHasClassName & {
  price?: Price$;
  oldPrice?: Price$;
  isOutOfStock?: boolean;
  from?: boolean;
  isAddToCartPopup?: boolean;
};

const ProductPrice = ({
  className,
  price,
  oldPrice,
  isOutOfStock,
  from,
  isAddToCartPopup = false,
}: ProductPriceProps) => {
  if (price === undefined) return null;

  if (isOutOfStock) {
    return <div className={cx(className, styles.outOfStock)}>Out of stock</div>;
  }

  return (
    <div className={cx(className, styles.root)}>
      <Price
        from={from}
        className={cx(
          styles.price,
          (oldPrice || from) && styles.price_highlighted,
          isAddToCartPopup && styles.isAddToCartPopup,
        )}
        value={price}
      />
      {oldPrice && (
        <Price
          className={cx(styles.oldPrice, isAddToCartPopup && styles.isAddToCartPopup)}
          value={oldPrice}
          old
        />
      )}
    </div>
  );
};

export type { ProductPriceProps };
export { ProductPrice };
