import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { User } from 'entities/authorization/user';

type Params = undefined;
type Data = User;

const NAME = '/Registration/GetCurrentUser';
const GetCurrentUserEndpoint = createModernEndpoint<Params, Data>(
  NAME,
  { method: 'GET', notifyWithSnackbar: false },
  { retry: 0, dedupingInterval: 1000 },
);

export default GetCurrentUserEndpoint;
export { NAME as GET_CURRENT_USER_ENDPOINT_NAME };
