import { useIsAndroid, useIsPWA } from '@swe/shared/tools/media';

enum PlatformOs {
  WEB = 'web',
  PWA_ANDROID = 'pwaAndroid',
}

const getPlatformOs = ({ isPWA, isAndroid }: { isPWA: boolean; isAndroid: boolean }) =>
  isPWA && isAndroid ? PlatformOs.PWA_ANDROID : PlatformOs.WEB;

const usePlatformOs = () => {
  const isPWA = useIsPWA();
  const isAndroid = useIsAndroid();

  return {
    platformOs: getPlatformOs({ isPWA, isAndroid }),
  };
};

export { usePlatformOs, PlatformOs, getPlatformOs };
