import { useRouterPathname } from '@swe/shared/providers/router';

import Button from '@swe/shared/ui-kit/components/button';
import Container from '@swe/shared/ui-kit/components/container';
import Toggle from '@swe/shared/ui-kit/components/form/toggle';
import { CloseIcon, DiscountIcon, MedicalIcon } from '@swe/shared/ui-kit/components/icon';

import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';

import { useAccountFulfillmentPreferences } from './use-cases/use-account-fulfillment-preferences';

import { SignupSubheader } from 'common/containers/header/containers/signup-subheader';
import SelectedLocation from 'common/containers/header/containers/sub-header/components/selected-location';
import { useStoreConfig } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { usePickupDeliveryCtx } from 'common/providers/pickup-delivery';
import { Routes } from 'common/router/constants';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { ProductSaleType } from 'entities/product/product';

import { ShopFulfillmentType } from 'entities/shop/info';

type SubHeaderProps = ComponentHasClassName;

const DELIVERY_BANNER_DELAY = 120000; // deafault 120 sec should be get from storeConfig

const SubHeader = ({ className }: SubHeaderProps) => {
  const { saleType, setSaleType, isMedicalMenuEnabled } = useSaleType();
  const { open } = usePickupDeliveryCtx();
  const { orderType, isPickupDeliveryAvailable, submittedAddress, storeInfo, isLoading } =
    useAccountFulfillmentPreferences();
  const { isGuest, isLoading: isLoadingGuest } = useGuest();
  const availableFulfillmentType = useMemo(
    () => storeInfo.fulfillmentTypes.find((type) => type === orderType) || storeInfo.fulfillmentTypes[0],
    [storeInfo.fulfillmentTypes, orderType],
  );
  const { stickyLoginBarEnabled, isTruncatedShop } = useStoreConfig();

  const pathname = useRouterPathname();
  const [isDeliveryDealAvailable, setIsDeliveryDealAvailable] = useState(false);

  useEffect(() => {
    if (orderType !== ShopFulfillmentType.Pickup) return;
    if (pathname === Routes.Cart) {
      const timeoutId = setTimeout(() => {
        setIsDeliveryDealAvailable(false);
      }, DELIVERY_BANNER_DELAY);

      setIsDeliveryDealAvailable(true);

      return () => clearTimeout(timeoutId);
    }
  }, [pathname, orderType]);

  const handleCloseDeliveryDealAvailable = useCallback(() => {
    setIsDeliveryDealAvailable(false);
  }, [setIsDeliveryDealAvailable]);

  const deliveryDealAvailableElement = useMemo(() => {
    // 4 case
    // set delivery
    return pathname === Routes.Cart &&
      storeInfo.deliveryPromos?.length &&
      orderType === ShopFulfillmentType.Pickup &&
      isDeliveryDealAvailable ? (
      <div className={styles.deliveryDeal}>
        <div className={styles.title}>
          <DiscountIcon className={styles.titleIcon} />
          <Text
            adaptive
            align="center"
            variant="control"
          >
            Delivery deal available
          </Text>
        </div>
        <div className={styles.deliveryDealActions}>
          <Button
            color="light"
            block
            className={styles.deliveryDealButton}
            size="xs"
            onClick={open}
            ariaLabel="Delivery deal available button"
          >
            <Text
              adaptive
              align="center"
              variant="control"
            >
              Set Delivery
            </Text>
          </Button>
          <Button
            ariaLabel="Delivery deal close button"
            size="xs"
            icon={CloseIcon}
            onClick={handleCloseDeliveryDealAvailable}
          />
        </div>
      </div>
    ) : null;
  }, [
    pathname,
    orderType,
    open,
    handleCloseDeliveryDealAvailable,
    isDeliveryDealAvailable,
    storeInfo.deliveryPromos?.length,
  ]);

  const medicalToggleSubheaderElement = useMemo(() => {
    if (deliveryDealAvailableElement) {
      return null;
    }

    if (isMedicalMenuEnabled && !isLoadingGuest && !isGuest) {
      return (
        <Toggle
          className={styles.toggle}
          size="lg"
          color="success"
          name="saleType"
          value={saleType === ProductSaleType.MEDICAL}
          onChange={(val) => setSaleType(val ? ProductSaleType.MEDICAL : ProductSaleType.RECREATIONAL)}
          valueLabels={[
            <>Adult Use</>,
            <>
              <MedicalIcon className={styles.medicalIcon} />
              &nbsp;Medical
            </>,
          ]}
        />
      );
    }

    return null;
  }, [deliveryDealAvailableElement, isMedicalMenuEnabled, isLoadingGuest, isGuest, saleType, setSaleType]);

  if (isTruncatedShop) {
    return null;
  }

  return (
    <>
      {!stickyLoginBarEnabled && <SignupSubheader />}
      <div className={cx(styles.root, className, !!deliveryDealAvailableElement && styles.deliveryDealContainer)}>
        <Container>
          <div className={styles.row}>
            {deliveryDealAvailableElement || (
              <SelectedLocation
                isReady={!isLoading && isPickupDeliveryAvailable}
                className={styles.fulfillment}
                type={availableFulfillmentType}
                storeName={storeInfo.name}
                address={submittedAddress}
                onClick={open}
              />
            )}
            {medicalToggleSubheaderElement}
          </div>
        </Container>
      </div>
    </>
  );
};

export type { SubHeaderProps };
export { SubHeader };
export default SubHeader;
