import { AnalyticsMeta } from 'common/entities/product-analitycs';
import { Routes } from 'common/router/constants';
import { buildNamedId } from 'common/router/utils';
import { Product, ProductVariant } from 'entities/product/product';

class ProductDetailsRouteBuildFailed extends Error {
  constructor(product: Product, variant: ProductVariant) {
    super(
      `Product(id:${product.id}, variant:${variant.id}) lacks data to build details route! Probably, it's missing category or name.`,
    );
  }
}

const createProductDetailsRoute = (product: Product, variant: ProductVariant, analyticsData?: AnalyticsMeta) => {
  const { category, subcategory, productType, name, strain } = product;
  const { id, name: variantName } = variant;
  try {
    const parts = [subcategory?.name, strain?.prevalence?.name, name, productType?.name, variantName].filter(
      Boolean,
    ) as string[];

    return {
      pathname: Routes.Catalog,
      query: {
        all: [buildNamedId(category.name, category.id), buildNamedId(parts, id)],
        ...(analyticsData
          ? Object.keys(analyticsData).reduce(
              (acc, key) => ({ ...acc, [key]: `${analyticsData[key as keyof typeof analyticsData]}` }),
              {},
            )
          : {}),
      },
    };
  } catch (e) {
    throw new ProductDetailsRouteBuildFailed(product, variant);
  }
};

export { createProductDetailsRoute, ProductDetailsRouteBuildFailed };
