import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { CreateAccountPayload } from 'entities/authorization/sign-up';

type Params = CreateAccountPayload;
type Data = {
  success: boolean;
  code: string | null;
  nextTryInSeconds: number;
};
type Error = APIErrorExtended;

const NAME = '/Registration/CreateAccount';
const SignupCreateAccountEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export { SignupCreateAccountEndpoint };
