import { ThemeIconSrcSize } from '@swe/shared/ui-kit/theme/provider';
import { DEFAULT_THEME, ThemeJSTokens } from '@swe/shared/ui-kit/theme/provider/constants';
import { Theme, ThemePattern } from '@swe/shared/ui-kit/theme/provider/themes';

import { isSSR } from '@swe/shared/utils/environment';

import { buildCssAndJsAssetsUrl, buildFontStyles, getUsedFonts, hasGoogleFonts } from 'app/theme/assets';
import { getClassName, getTokensClient, getTokensServer } from 'app/theme/getters';
import { ThemeBuild } from 'app/theme/types';
import { hashThemeName } from 'app/theme/utils';

const loadTokens = async (themeName?: string) => {
  try {
    const module = await import(`@swe/shared/ui-kit/theme/provider/themes/${themeName}/index.mjs`);

    return module.default;
  } catch (e) {
    console.error(`THEME:${themeName} FAILED TO LOAD. SKIPPING TO DEFAULT.`, e);
    const module = await import(`@swe/shared/ui-kit/theme/provider/themes/${DEFAULT_THEME}/index.mjs`);
    return module.default;
  }
};
const loadTheme = async (rawThemeName: Theme, assetPrefix?: AbsoluteURL): Promise<ThemeBuild> => {
  let themeName = hashThemeName(rawThemeName);
  const tokens = await loadTokens(themeName);
  themeName = DEFAULT_THEME in tokens ? DEFAULT_THEME : themeName;
  const [cssUrl, jsUrl] = buildCssAndJsAssetsUrl(themeName, assetPrefix);
  const themeTokens = tokens[themeName] as ThemeJSTokens;
  const cssFonts = themeTokens ? buildFontStyles(themeTokens, assetPrefix) : '';
  const fonts = themeTokens ? getUsedFonts(themeTokens, assetPrefix) : [];
  const _hasGoogleFonts = themeTokens ? hasGoogleFonts(themeTokens, assetPrefix) : false;

  return {
    themeName,
    tokens,
    cssUrl,
    jsUrl,
    cssFonts,
    fonts,
    hasGoogleFonts: _hasGoogleFonts,
    assetPrefix,
    color: themeTokens.header.topBackgroundColor,
    baseRemSize: themeTokens.baseRemSize,
  };
};

const cleanThemeBuildForClient = (_build: ThemeBuild) => {
  const { tokens, ...build } = _build;
  return build;
};

const getThemeUtils = ({ themeName, tokens, assetPrefix }: ThemeBuild) => {
  return {
    getTokens: (themePattern?: ThemePattern) =>
      isSSR ? getTokensServer(tokens, themeName, themePattern) : getTokensClient(themeName, themePattern),
    getClassName: (themePattern?: ThemePattern) => getClassName(themeName, themePattern),
    getIconSrc: ({ iconSize, iconName }: { iconName: string; iconSize: ThemeIconSrcSize }) =>
      `${assetPrefix ?? ''}/_themes/${themeName}/icons/${iconSize}/${iconName}.svg`,
    getIllustrationSrc: ({ illustrationName }: { illustrationName: string }) =>
      `${assetPrefix ?? ''}/_themes/${themeName}/illustrations/${illustrationName}.svg`,
  };
};

export { loadTheme, getThemeUtils, cleanThemeBuildForClient };
