import cx from 'clsx';

import merge from 'lodash/merge';
import { createContext, forwardRef, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Globals } from 'react-spring';

// eslint-disable-next-line import/no-cycle
import { useIsAndroid, useIsIOS } from '@swe/shared/tools/media';

import { ThemePattern } from '@swe/shared/ui-kit/theme/provider/themes';
import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { isSSR } from '@swe/shared/utils/environment';

import { ThemeJSTokens } from './constants';
import styles from './styles.module.scss';

Globals.assign({
  skipAnimation: true,
});

export type ThemeIconSrcSize = 16 | 24;

type TokensGetter = (themePattern?: ThemePattern) => ThemeJSTokens;
type ClassNameGetter = (themePattern?: ThemePattern) => string;
export type IconSrcResolver = (params: { iconName: string; iconSize: ThemeIconSrcSize }) => string;
export type IllustrationSrcResolver = (params: { illustrationName: string }) => string;
type Getters = {
  getTokens: TokensGetter;
  getClassName: ClassNameGetter;
  getIconSrc: IconSrcResolver;
  getIllustrationSrc: IllustrationSrcResolver;
};

const themeContext = createContext<ThemeJSTokens>(null!);
const ThemeContextProvider = themeContext.Provider;
export const useTheme = () => useContext(themeContext);

type AnimationSettings = {
  enabled: boolean;
};
const animationSettingsContext = createContext<AnimationSettings>(null!);
const AnimationSettingsContextProvider = animationSettingsContext.Provider;
export const useAnimationSettings = () => useContext(animationSettingsContext);

const themeGetterContext = createContext<Getters>(null!);
const ThemeGetterContextProvider = themeGetterContext.Provider;
export const useThemeGetter = () => useContext(themeGetterContext);

export type ThemeProviderProps = {
  children?: ReactNode;
} & Getters &
  ComponentHasClassName;

const ThemeProvider = ({
  children,
  className,
  getTokens,
  getClassName,
  getIconSrc,
  getIllustrationSrc,
}: ThemeProviderProps) => {
  const jsTokens = useMemo(() => getTokens(), [getTokens]);
  const themeClassName = useMemo(() => getClassName(), [getClassName]);
  const getterCtx = useMemo(
    () => ({ getTokens, getClassName, getIconSrc, getIllustrationSrc }),
    [getClassName, getIconSrc, getIllustrationSrc, getTokens],
  );
  const isAndroid = useIsAndroid();
  const isIOS = useIsIOS();
  const [animationsEnabled, setAnimationsEnabled] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (!isAndroid && !isIOS) {
        setAnimationsEnabled(true);
        Globals.assign({
          skipAnimation: false,
        });
      }
    }, 6000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const animationsSettings = useMemo(() => ({ enabled: isSSR ? false : animationsEnabled }), [animationsEnabled]);

  return (
    jsTokens && (
      <AnimationSettingsContextProvider value={animationsSettings}>
        <ThemeContextProvider value={jsTokens}>
          <ThemeGetterContextProvider value={getterCtx}>
            <div className={cx(className, themeClassName, !animationsSettings.enabled && styles._animations_disabled)}>
              <div className={styles.container}>{children}</div>
            </div>
          </ThemeGetterContextProvider>
        </ThemeContextProvider>
      </AnimationSettingsContextProvider>
    )
  );
};

const PATTERN_TOKENS: Record<string, ThemeJSTokens> = {};

export const usePatternTheme = (themePattern?: ThemePattern) => {
  const themeTokens = useTheme();
  const { getTokens, getClassName } = useThemeGetter();

  const className = useMemo(() => (themePattern ? getClassName(themePattern) : ''), [getClassName, themePattern]);

  const fullThemeTokens: ThemeJSTokens = useMemo(() => {
    if (!themePattern) {
      return themeTokens;
    }
    if (PATTERN_TOKENS[className]) {
      return PATTERN_TOKENS[className]!;
    }
    const patternTokens = getTokens(themePattern);
    PATTERN_TOKENS[className] = merge({}, themeTokens, patternTokens);
    return PATTERN_TOKENS[className]!;
  }, [getTokens, themePattern, themeTokens, className]);

  return {
    className,
    tokens: fullThemeTokens,
  };
};

type PatternThemeProviderProps = ComponentHasChildren &
  ComponentHasClassName & {
    name?: ThemePattern;
    pseudoBox?: boolean;
  };

export const PatternThemeProvider = forwardRef<HTMLDivElement, PatternThemeProviderProps>(
  ({ children, name, className, pseudoBox }, ref) => {
    const { className: patternClassName, tokens } = usePatternTheme(name);

    return (
      <ThemeContextProvider value={tokens}>
        <div
          className={cx(className, patternClassName, pseudoBox && styles._hasPseudoBox)}
          ref={ref}
        >
          {children}
        </div>
      </ThemeContextProvider>
    );
  },
);

export default ThemeProvider;
