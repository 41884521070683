import { setGlobalHeaders } from '@swe/shared/network/endpoint-factories/modern/fetcher';
import { setCookie } from '@swe/shared/tools/cookie';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { addDays } from '@swe/shared/utils/date';

import { createContext, ReactNode, useContext, useEffect } from 'react';

import { LAST_STORE_COOKIE_NAME } from 'app/config';
import { ShopConfig } from 'entities/shop/config';

const context = createContext<ShopConfig>(null!);
const ConfigContextProvider = context.Provider;

type StoreConfigProviderProps = ComponentHasChildren<ReactNode | ((config: ShopConfig) => ReactNode)> & {
  storeConfig: ShopConfig;
};

const StoreConfigProvider = ({ children, storeConfig }: StoreConfigProviderProps) => {
  setGlobalHeaders({ StoreId: storeConfig.id });

  useEffect(() => {
    setCookie(
      LAST_STORE_COOKIE_NAME,
      { id: storeConfig.id, url: storeConfig.url, routeName: storeConfig.routeName },
      { expires: addDays(new Date(), 365) },
    );
  }, [storeConfig]);

  return (
    <ConfigContextProvider value={storeConfig}>
      {typeof children === 'function' ? children(storeConfig) : children}
    </ConfigContextProvider>
  );
};

const useStoreConfig = () => useContext(context);

export { useStoreConfig, StoreConfigProvider };
export default StoreConfigProvider;
