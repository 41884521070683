import Button from '@swe/shared/ui-kit/components/button';
import { ChatIcon } from '@swe/shared/ui-kit/components/icon';
import Section from '@swe/shared/ui-kit/components/section';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import styles from './styles.module.scss';

import ReferralBanner from 'common/containers/referral-banner';
import { useStoreConfig } from 'common/providers/config';
import { usePWA } from 'common/providers/pwa';
import { Routes, ShopRoute } from 'common/router/constants';
import useMedChat from 'common/use-cases/use-med-chat';
import { OrderCard } from 'domains/profile/components/order-card';
import { useOrdersHistory } from 'domains/profile/use-cases/use-orders-history';

type MobileMenuProps = ComponentHasClassName & {
  items: { href: ShopRoute; label: string }[];
  onItemClick: (href: ShopRoute) => void;
  loyaltyPts?: string;
};

const MobileMenu = ({ className, items, onItemClick, loyaltyPts }: MobileMenuProps) => {
  const { lastNonCompleted } = useOrdersHistory();
  const { externalNav = [], isTruncatedShop } = useStoreConfig();
  const { open } = useMedChat();
  const { install } = usePWA();

  return (
    <div className={cx(styles.root, className)}>
      <Stack>
        {!isTruncatedShop && <ReferralBanner />}
        {!!lastNonCompleted && <OrderCard order={lastNonCompleted} />}

        <Stack
          spacing="none"
          divider
        >
          {items.map((item) => (
            <SectionHeading
              key={item.label}
              size="md"
              onClick={() => onItemClick(item.href)}
              badge={loyaltyPts && item.href === Routes.Profile ? { children: loyaltyPts } : undefined}
            >
              {item.label}
            </SectionHeading>
          ))}
          {install.isInstallable && !install.isBannerVisible && (
            <SectionHeading
              size="md"
              onClick={install.install}
            >
              Download app
            </SectionHeading>
          )}
          {externalNav.map((item) =>
            'href' in item ? (
              <SectionHeading
                key={item.label}
                size="md"
                href={item.href}
              >
                {item.label}
              </SectionHeading>
            ) : (
              <Section
                key={item.label}
                expandable
                heading={{ size: 'md', children: item.label }}
              >
                {item.children.map((link) => (
                  <SectionHeading
                    size="sm"
                    key={link.href}
                    href={link.href}
                  >
                    {link.label}
                  </SectionHeading>
                ))}
              </Section>
            ),
          )}
        </Stack>
        {open && (
          <Button
            icon={ChatIcon}
            size="sm"
            onClick={open}
            ariaLabel="med chat"
          >
            Chat
          </Button>
        )}
      </Stack>
    </div>
  );
};

export { MobileMenu };
export default MobileMenu;
