import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ShopConfigDTO } from 'entities/shop/config';

type Data = ShopConfigDTO;
type Params = {
  domain?: string;
  routeName?: string;
};

const NAME = '/Store/GetStoreConfig';
const GetShopConfigEndpoint = createModernEndpoint<Params, Data>(NAME, undefined, {
  dedupingInterval: 10 * 60 * 1000,
});

export default GetShopConfigEndpoint;
export { GetShopConfigEndpoint };
