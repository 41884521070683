import { createContext, ErrorInfo, ReactNode, useContext, useMemo } from 'react';
import {
  ErrorBoundary as ErrorBoundaryExternal,
  FallbackProps,
  ErrorBoundaryProps as ErrorBoundaryExternalProps,
} from 'react-error-boundary';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type ErrorLevel = 'fatal' | 'error' | 'warning';

type ErrorBoundaryProps = ComponentHasChildren & {
  fallback?: ReactNode | ((args: FallbackProps) => ReactNode);
  onError?: (error: Error, info: ErrorInfo, hint: { level?: ErrorLevel }) => void;
  level?: ErrorLevel;
};

type ErrorBoundaryContext = {
  defaultFallback?: ErrorBoundaryProps['fallback'];
  defaultOnError?: ErrorBoundaryProps['onError'];
};
const errorBoundaryContext = createContext<ErrorBoundaryContext>({ defaultFallback: <p>Fatal client error</p> });
const ErrorBoundaryContextProvider = errorBoundaryContext.Provider;
const useErrorBoundaryDefaults = () => useContext(errorBoundaryContext);

const ErrorBoundary = ({ children, fallback, onError, level }: ErrorBoundaryProps) => {
  const { defaultOnError, defaultFallback } = useErrorBoundaryDefaults();
  const errorHandler: ErrorBoundaryExternalProps['onError'] = (error, info) => {
    if (onError) {
      onError(error, info, { level });
    } else if (defaultOnError) {
      defaultOnError(error, info, { level });
    } else {
      console.error(error, info, { level });
    }
  };

  const finalFallback = fallback ?? defaultFallback;

  const props = (
    typeof finalFallback === 'function'
      ? { fallbackRender: finalFallback, onError: errorHandler, children }
      : { fallback: finalFallback, onError: errorHandler, children }
  ) as ErrorBoundaryExternalProps;

  return <ErrorBoundaryExternal {...props} />;
};

type ErrorBoundaryProviderProps = ComponentHasChildren & ErrorBoundaryContext;
const ErrorBoundaryProvider = ({ defaultFallback, defaultOnError, children }: ErrorBoundaryProviderProps) => {
  return (
    <ErrorBoundaryContextProvider
      value={useMemo(() => ({ defaultOnError, defaultFallback }), [defaultOnError, defaultFallback])}
    >
      {children}
    </ErrorBoundaryContextProvider>
  );
};

export { ErrorBoundary, ErrorBoundaryProvider };
export type { ErrorBoundaryProps };
