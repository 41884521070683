import { useBreakpoint } from '@swe/shared/tools/media';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { createContext, useMemo, useContext, lazy, Suspense } from 'react';

import { usePickupDeliveryToggle } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery';

type PickupDeliveryContext = {
  isOpened: boolean;
  open(): void;
  close(): void;
};

const context = createContext<PickupDeliveryContext>({
  isOpened: false,
  open: () => {},
  close: () => {},
});

const PickupDeliveryContextProvider = context.Provider;

const usePickupDeliveryCtx = () => useContext(context);

type PickupDeliveryProviderProps = ComponentHasChildren;

const DynamicDesktopModals = lazy(() => import('./desktop'));
const DynamicMobileModals = lazy(() => import('./mobile'));

const PickupDeliveryProvider = ({ children }: PickupDeliveryProviderProps) => {
  const { isOpened, open, close } = usePickupDeliveryToggle();
  const { mobile } = useBreakpoint();

  const ctx = useMemo<PickupDeliveryContext>(
    () => ({
      isOpened,
      open,
      close,
    }),
    [close, isOpened, open],
  );

  const dynamicModalComponent = isOpened && (
    <Suspense>{mobile ? <DynamicMobileModals /> : <DynamicDesktopModals />}</Suspense>
  );
  return (
    <PickupDeliveryContextProvider value={ctx}>
      {dynamicModalComponent}
      {children}
    </PickupDeliveryContextProvider>
  );
};

export type { PickupDeliveryContext };
export { usePickupDeliveryCtx, PickupDeliveryProvider };
export default PickupDeliveryProvider;
