import { useCallback } from 'react';

import Alert from '@swe/shared/ui-kit/components/alert';
import { Hero } from '@swe/shared/ui-kit/components/hero';

import { Error500Illustration } from '@swe/shared/ui-kit/components/illustration';

import Link from '@swe/shared/ui-kit/components/link';
import { truncate } from '@swe/shared/utils/string';

import styles from './styles.module.scss';

type Error500Props = {
  error?: any;
};

const REFRESH_ID_PARAM = '__rid';

const Error500 = ({ error }: Error500Props) => {
  const errorString =
    typeof error === 'string'
      ? error
      : typeof error === 'object' && 'stack' in error
        ? error.stack
        : JSON.stringify(error, null, 2);
  const maxErrorString = truncate(errorString, { length: 500 });

  const refresh = useCallback(() => {
    const newUrl = new URL(window.location.href);
    if (newUrl.searchParams.has(REFRESH_ID_PARAM)) {
      newUrl.searchParams.delete(REFRESH_ID_PARAM);
    }
    newUrl.searchParams.append(REFRESH_ID_PARAM, String(Math.round(Math.random() * 100)));
    window.location.replace(newUrl.href);
  }, []);

  return (
    <Hero
      illustration={<Error500Illustration />}
      title="Oops, something went wrong 😓"
      content={
        <>
          <div>
            Try to <Link onClick={refresh}>refresh this page</Link> or feel free to contact our support if the problem
            persists.
          </div>
          {error && (
            <Alert
              className={styles.error}
              color="danger"
            >
              {maxErrorString}
            </Alert>
          )}
        </>
      }
      border={false}
    />
  );
};

export type { Error500Props };
export { Error500 };
