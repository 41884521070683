import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import AnimatedHeight from '@swe/shared/ui-kit/components/animated-height';
import Button, { ButtonProps } from '@swe/shared/ui-kit/components/button';
import { ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';

import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import Stack from '@swe/shared/ui-kit/components/stack';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';

import React, { useCallback } from 'react';

import styles from './styles.module.scss';

import { ProductSection } from 'common/components/product-section';
import { buildNamedId } from 'common/router/utils';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { DiscountInfo } from 'domains/product/components/discount-card/components/discount-info';
import { DiscountSkeleton } from 'domains/product/components/product-details/components/discount/skeleton';
import { CompilationType } from 'entities/common/compilation';
import { Discount } from 'entities/discount/discount';
import { ProductVariant } from 'entities/product/product';

type PromoProps = {
  promo: Discount;
};

const PromoDetails = ({ promo }: PromoProps) => {
  const { name, id, description, scheduleDisplayText, fulfilmentTypes } = promo;
  const { toCatalog } = useCatalogQuery();
  const { pdp } = useTheme();
  const [isExpanded, , , toggle] = useToggleable(false);
  const [isMarginSet, enableMargin, disableMargin] = useToggleable(false);

  const onHeightAnimationStart = useCallback(() => {
    if (isExpanded) {
      enableMargin();
    }
  }, [enableMargin, isExpanded]);
  const onHeightAnimationEnd = useCallback(() => {
    if (!isExpanded) {
      disableMargin();
    }
  }, [disableMargin, isExpanded]);
  const toAllProducts = useCallback(
    () => toCatalog({ filters: null, subPath: [CompilationType.DISCOUNT, buildNamedId(name, id)], searchTerm: null }),
    [id, name, toCatalog],
  );

  return (
    <div className={styles.promo}>
      <div className={styles.promoTitle}>{name}</div>

      <DiscountInfo
        schedule={scheduleDisplayText}
        fulfilmentTypes={fulfilmentTypes}
      />
      {/* 5 case Promo info on the PDP */}

      {description && (
        <AnimatedHeight
          className={!isMarginSet ? styles.promoDescriptionWrapper_hidden : undefined}
          transition
          expanded={isExpanded}
          onHeightAnimationStart={onHeightAnimationStart}
          onHeightAnimationEnd={onHeightAnimationEnd}
        >
          <div className={styles.promoDescription}>{description}</div>
        </AnimatedHeight>
      )}

      <div className={styles.promoActions}>
        {description && (
          <Button
            ariaLabel={`Show more info about ${promo.name}`}
            variant="link"
            size="sm"
            color={pdp.deal.button as ButtonProps['color']}
            onClick={toggle}
          >
            {isExpanded ? 'Less ' : 'More '} Info
          </Button>
        )}
        <Button
          ariaLabel={`Show all products from ${promo.name}`}
          variant="link"
          size="sm"
          endIcon={ChevronRightIcon}
          color={pdp.deal.button as ButtonProps['color']}
          onClick={toAllProducts}
        >
          All Products
        </Button>
      </div>
    </div>
  );
};

type ProductDiscountProps = {
  variant: ProductVariant;
};

const ProductDiscount = ({ variant }: ProductDiscountProps) => {
  const { promos } = variant;

  if (!promos || promos.length === 0) {
    return null;
  }

  // TODO: Delete type cast and fix date typings
  return (
    <ProductSection
      title="Discounts"
      expandable={false}
    >
      <Stack>
        {promos.map((promo) => (
          <PromoDetails
            key={promo.id}
            promo={promo as Discount}
          />
        ))}
      </Stack>
    </ProductSection>
  );
};

const ProductDiscountSkeletonized = withSkeleton(ProductDiscount, DiscountSkeleton, ['variant']);

export type { ProductDiscountProps };
export { ProductDiscount, ProductDiscountSkeletonized };
export default ProductDiscount;
