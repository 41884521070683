import { Geolocation, GeolocationBus } from '@swe/shared/hooks/use-geolocation';
import { useMounted } from '@swe/shared/hooks/use-mounted';
import { useStateWithPrevious } from '@swe/shared/hooks/use-state';
import { useGoogleApiContext } from '@swe/shared/providers/google-api';
import { useModalRoute } from '@swe/shared/providers/router/modal';
import { UserMarker } from '@swe/shared/ui-kit/components/google-map';
import { USA_CENTER } from '@swe/shared/ui-kit/components/google-map/config';
import { GoogleMapContext } from '@swe/shared/ui-kit/components/google-map/context';

import { UserIcon } from '@swe/shared/ui-kit/components/icon';
import Pin from '@swe/shared/ui-kit/components/pin';
import { usePrompt } from '@swe/shared/ui-kit/components/promt';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MapProps } from 'common/containers/header/containers/sub-header/containers/map';
import { useDelivery } from 'common/containers/header/containers/sub-header/use-cases/use-delivery';
import { usePickup } from 'common/containers/header/containers/sub-header/use-cases/use-pickup';
import { usePickupDeliverySettings } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery-settings';
import { useCartQuantity } from 'common/providers/cart';
import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { ModalRoutes } from 'common/router/constants';

import { LocationCoords, ShopFulfillmentType } from 'entities/shop/info';

enum View {
  OrderType = 'OrderSettings/Root',
  DeliveryAddressInput = 'OrderSettings/Delivery/Address',
  PickupStoreList = 'OrderSettings/Pickup/StoreList',
  DeliveryStoreList = 'OrderSettings/Delivery/StoreList',
  StoreDetails = 'OrderSettings/StoreDetails',
}

type UseMapDraggingArg = Pick<MapProps, 'onDragStart' | 'onDragEnd'>;

type AdjustMapArg =
  | {
      center?: LocationCoords;
      zoom?: number;
      points?: undefined;
    }
  | { points: LocationCoords[]; center?: undefined; zoom?: undefined };

const useMapDragging = ({ onDragStart, onDragEnd }: UseMapDraggingArg = {}) => {
  const [isDragging, setDragging] = useState(false);

  const handleDragStart = useCallback(() => {
    setDragging(true);
    onDragStart?.();
  }, [onDragStart]);
  const handleDragEnd = useCallback(
    (location?: LocationCoords) => {
      setDragging(false);
      if (location) {
        onDragEnd?.(location);
      }
    },
    [onDragEnd],
  );

  return {
    isDragging,
    handleDragStart,
    handleDragEnd,
  };
};

const usePickupDeliveryToggle = () => {
  const { isOpened, open: _open, close } = useModalRoute(ModalRoutes.Locations);
  const { load: loadGoogleMap } = useGoogleApiContext();

  const open = useCallback(async () => {
    await loadGoogleMap();
    await _open();
  }, [loadGoogleMap, _open]);

  return {
    isOpened,
    open,
    close,
  };
};

const usePickupDelivery = () => {
  const {
    currentStoreId,
    orderType: submittedOrderType,
    availableFulfillment,
    deliveryAddress: submittedDeliveryAddress,
    isPickupDeliveryAvailable,
  } = usePickupDeliverySettings();

  const { isOpened, open, close } = usePickupDeliveryToggle();
  const [orderType, _setOrderType] = useState(submittedOrderType);
  const [view, setView, previousView] = useStateWithPrevious(View.OrderType);
  const mapRef = useRef<GoogleMapContext>(null);

  const pickup = usePickup({
    isEnabled: isOpened && orderType === ShopFulfillmentType.Pickup,
  });
  const delivery = useDelivery({
    isEnabled: isOpened && orderType === ShopFulfillmentType.Delivery,
    selectedStoreId: pickup.selectedStoreId,
  });

  const {
    currentStore,
    selectedStoreId,
    selectedStore,
    getById,
    setSelectedStoreId,
    save: savePickupSettings,
    clear: clearPickup,
    isLoading: isPickupLoading,
  } = pickup;
  const {
    deliveryLocation,
    deliveryStore,
    setRealLocationAsDelivery,
    realLocation,
    setSelectedDeliveryLocation,
    setSelectedDeliveryAddress,
    save: saveDeliverySettings,
    clear: clearDelivery,
  } = delivery;

  const isStorePickupAvailable = useMemo(() => {
    const store = selectedStore || currentStore;
    if (!store?.fulfillmentTypes) return false;
    return store.fulfillmentTypes.includes(ShopFulfillmentType.Pickup);
  }, [selectedStore, currentStore]);

  const adjustMap = useCallback(({ zoom, center, points }: AdjustMapArg) => {
    const map = mapRef.current?.map;
    if (points) {
      mapRef.current?.fitPoints(points);
      return;
    }
    if (zoom) map?.setZoom(zoom);
    if (center) map?.setCenter(center);
  }, []);

  const setDeliveryAddress = useCallback(
    async (address: string) => {
      const { location } = await setSelectedDeliveryAddress(address);
      mapRef.current?.map?.setCenter(location);
      setView(View.OrderType);
    },
    [setSelectedDeliveryAddress, setView],
  );
  const setOrderType = useCallback(
    (ot: ShopFulfillmentType) => {
      _setOrderType(ot);
      switch (ot) {
        case ShopFulfillmentType.Pickup:
          adjustMap(
            realLocation
              ? { points: [realLocation, currentStore.location.coords] }
              : {
                  center: selectedStore?.location.coords ?? currentStore?.location.coords,
                  zoom: 15,
                },
          );
          break;
        case ShopFulfillmentType.Delivery:
          adjustMap({
            center: deliveryLocation ?? realLocation,
            zoom: 15,
          });
          break;
      }
    },
    [adjustMap, currentStore.location.coords, deliveryLocation, realLocation, selectedStore?.location.coords],
  );
  const setStore = useCallback(
    (id?: EntityID, orderSettingsView?: View.OrderType | View.StoreDetails) => {
      const store = getById(id);
      if (store) {
        setView(orderSettingsView ?? View.OrderType);
        setSelectedStoreId(store.id);

        if (view === View.DeliveryStoreList) return;

        adjustMap({
          center: store.location.coords,
          zoom: 15,
        });
      }
    },
    [adjustMap, getById, setSelectedStoreId, setView, view],
  );
  const showStorePreview = useCallback(
    (id?: EntityID) => {
      setStore(id, View.StoreDetails);
    },
    [setStore],
  );
  const showStoreList = useCallback(
    (view: View.PickupStoreList | View.DeliveryStoreList) => {
      const storesAvailable = (view === View.PickupStoreList ? pickup.stores : delivery.availableStores).length > 1;

      if (!storesAvailable) return;

      setView(view);
      if (view === View.DeliveryStoreList) return;
      adjustMap({
        center: USA_CENTER,
        zoom: 3,
      });
    },
    [adjustMap, setView, pickup.stores, delivery.availableStores],
  );

  const showAddressSearch = useCallback(() => {
    setView(View.DeliveryAddressInput);
  }, [setView]);
  const goInitialView = useCallback(() => {
    setView(View.OrderType);
    adjustMap({
      center:
        orderType === ShopFulfillmentType.Pickup
          ? selectedStore?.location.coords ?? currentStore?.location.coords
          : deliveryLocation ?? deliveryStore?.location.coords,
      zoom: 15,
    });
  }, [
    adjustMap,
    currentStore?.location.coords,
    deliveryLocation,
    deliveryStore?.location.coords,
    orderType,
    selectedStore?.location.coords,
    setView,
  ]);
  const goPreviousView = useCallback(() => {
    const previous = previousView.current;
    switch (previous) {
      case View.OrderType:
        adjustMap({
          center:
            orderType === ShopFulfillmentType.Pickup
              ? selectedStore?.location.coords ?? currentStore?.location.coords
              : deliveryLocation ?? deliveryStore?.location.coords,
          zoom: 15,
        });
        break;
      case View.PickupStoreList:
        adjustMap({
          center: USA_CENTER,
          zoom: 3,
        });
        break;
      case View.StoreDetails:
      case View.DeliveryAddressInput:
      default:
        break;
    }
    setView(previous ?? View.OrderType);
  }, [
    adjustMap,
    currentStore?.location.coords,
    deliveryLocation,
    deliveryStore?.location.coords,
    orderType,
    previousView,
    selectedStore?.location.coords,
    setView,
  ]);

  const { confirm } = usePrompt();
  const quantity = useCartQuantity();
  const isCartEmpty = quantity === 0;
  const isSameStore =
    orderType === ShopFulfillmentType.Delivery
      ? !deliveryStore || deliveryStore?.id === currentStore.id
      : !selectedStore || selectedStore?.id === currentStore.id;

  const { logout } = useCurrentUser();

  const { isGuest } = useGuest();

  const submit = useCallback(async () => {
    if (
      isSameStore ||
      isCartEmpty ||
      (await confirm({
        heading: 'Cart reset',
        ariaLabel: 'Cart reset',
        content:
          'You’re about to switch to another shop, which assortment may differ from the current shop. Your cart will be emptied.',
      }))
    ) {
      switch (orderType) {
        case ShopFulfillmentType.Delivery:
          await saveDeliverySettings();
          break;
        case ShopFulfillmentType.Pickup:
          await savePickupSettings();
          break;
      }
      void close();
      if (!isSameStore && isGuest) {
        void logout(true, true);
      }
    }
  }, [isSameStore, isCartEmpty, confirm, orderType, close, isGuest, saveDeliverySettings, savePickupSettings, logout]);

  const clear = useCallback(() => {
    clearPickup();
    clearDelivery();
    setOrderType(submittedOrderType);
    setView(View.OrderType);
  }, [clearDelivery, clearPickup, setOrderType, setView, submittedOrderType]);

  const { isDragging, handleDragStart, handleDragEnd } = useMapDragging({
    onDragEnd: useCallback(
      (location?: LocationCoords) => {
        if (location) void setSelectedDeliveryLocation(location);
      },
      [setSelectedDeliveryLocation],
    ),
  });
  const mapProps = useMemo<MapProps>(() => {
    const commonProps: Pick<MapProps, 'controls'> = {
      controls: ['geo', 'zoom'],
    };

    if (orderType === ShopFulfillmentType.Pickup) {
      return {
        ...commonProps,
        ref: mapRef,
        fulfillmentType: orderType,
        currentStoreId: selectedStoreId ?? currentStoreId,
        onShopSelect: setStore,
        children: realLocation ? <UserMarker position={realLocation} /> : undefined,
      };
    }

    return {
      ...commonProps,
      ref: mapRef,
      fulfillmentType: orderType,
      currentStoreId: deliveryStore?.id,
      centerPin: (
        <Pin
          color="warning"
          icon={UserIcon}
          selfAligned
          uplifted={isDragging}
        />
      ),
      user: deliveryLocation,
      onPanToMe: setRealLocationAsDelivery,
      onDragStart: handleDragStart,
      onDragEnd: handleDragEnd,
    };
  }, [
    orderType,
    selectedStoreId,
    currentStoreId,
    deliveryStore?.id,
    isDragging,
    deliveryLocation,
    realLocation,
    setRealLocationAsDelivery,
    handleDragStart,
    handleDragEnd,
    setStore,
  ]);
  //
  // const syncData = useCallback(() => {
  //   setOrderType(submittedOrderType);
  //   void setDeliveryAddress(submittedDeliveryAddress || '');
  // }, [setDeliveryAddress, setOrderType, submittedDeliveryAddress, submittedOrderType]);

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        switch (view) {
          case View.OrderType:
            if (orderType === ShopFulfillmentType.Delivery) {
              adjustMap({
                center: deliveryLocation ?? realLocation ?? deliveryStore?.location.coords,
                zoom: 15,
              });
            }

            if (orderType === ShopFulfillmentType.Pickup) {
              adjustMap(
                realLocation
                  ? { points: [realLocation, currentStore.location.coords] }
                  : {
                      center: selectedStore?.location.coords ?? currentStore?.location.coords,
                      zoom: 15,
                    },
              );
            }
            break;
          case View.PickupStoreList:
          case View.StoreDetails:
          case View.DeliveryAddressInput:
            adjustMap({
              center: USA_CENTER,
              zoom: 3,
            });
            break;
        }
      }, 20);
    } else {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  useMounted(() => {
    const onAcquire = (location: Geolocation) => {
      adjustMap({
        points: [{ lat: location.latitude, lng: location.longitude }, currentStore.location.coords],
      });
      GeolocationBus.off('onAcquire', onAcquire);
    };

    GeolocationBus.on('onAcquire', onAcquire);

    return () => {
      GeolocationBus.off('onAcquire', onAcquire);
    };
  });

  return {
    pickup,
    delivery,
    availableFulfillment,
    setOrderType,
    isStorePickupAvailable,
    view,
    orderType,
    submittedOrderType,
    isOpened,
    isDragging,
    isPickupDeliveryAvailable,
    isLoading: isPickupLoading,
    open,
    close,
    clear,

    mapRef,
    mapProps,
    displayedAddress:
      orderType === ShopFulfillmentType.Delivery ? submittedDeliveryAddress : currentStore?.contacts.address,
    submittedAddress:
      submittedOrderType === ShopFulfillmentType.Pickup ? currentStore?.contacts.address : submittedDeliveryAddress,

    goPreviousView,
    goInitialView,
    showStoreList,
    showStorePreview,
    showAddressSearch,
    setDeliveryAddress,
    submit,
    setStore,
  };
};

export { usePickupDelivery, usePickupDeliveryToggle, View };
