import React, { useMemo } from 'react';

import { QueryProvider, EndpointFactoryProviderProps } from '@swe/shared/network/transport/query';
import { GeolocationProvider } from '@swe/shared/providers/geolocation';
import { LayoutProvider } from '@swe/shared/providers/layout';
import { IntlProvider } from '@swe/shared/tools/intl';
import { Language } from '@swe/shared/tools/intl/core/i18n';
import { IntlProviderProps } from '@swe/shared/tools/intl/core/provider';
import { MediaProvider } from '@swe/shared/tools/media';
import { ErrorBoundary, ErrorBoundaryProps, ErrorBoundaryProvider } from '@swe/shared/ui-kit/components/error-boundary';
import { PortalProvider, PORTALS } from '@swe/shared/ui-kit/components/portal';
import { PromptProvider } from '@swe/shared/ui-kit/components/promt';
import Scrollable from '@swe/shared/ui-kit/components/scrollable';
import ThemeProvider, { ThemeProviderProps } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type SharedProviderProps = ComponentHasChildren & {
  errorFallback?: ErrorBoundaryProps['fallback'];
  onError?: ErrorBoundaryProps['onError'];

  revalidateOnInit?: EndpointFactoryProviderProps['revalidateOnInit'];
  externalApiHost?: EndpointFactoryProviderProps['externalApiHost'];
  externalApiBasePath?: EndpointFactoryProviderProps['externalApiBasePath'];

  getTokens: ThemeProviderProps['getTokens'];
  getClassName: ThemeProviderProps['getClassName'];
  getIconSrc: ThemeProviderProps['getIconSrc'];
  getIllustrationSrc: ThemeProviderProps['getIllustrationSrc'];
  clientWidth: number;
  isIOS?: boolean;
  isAndroid?: boolean;
  isPWA?: boolean;

  messages?: IntlProviderProps['messages'];
};

const SharedProvider = ({ children, ...props }: SharedProviderProps) => {
  return (
    <ErrorBoundaryProvider
      defaultFallback={props.errorFallback}
      defaultOnError={props.onError}
    >
      <MediaProvider
        value={useMemo(
          () => ({
            clientWidth: props.clientWidth,
            isIOS: props.isIOS ?? false,
            isAndroid: props.isAndroid ?? false,
            isPWA: props.isPWA ?? false,
          }),
          [props.clientWidth, props.isIOS, props.isAndroid, props.isPWA],
        )}
      >
        <ThemeProvider
          getTokens={props.getTokens}
          getClassName={props.getClassName}
          getIconSrc={props.getIconSrc}
          getIllustrationSrc={props.getIllustrationSrc}
        >
          <ErrorBoundary level="fatal">
            <IntlProvider
              messages={props.messages ?? {}}
              language={Language.En}
            >
              <Scrollable
                isRoot
                fade={false}
              >
                <LayoutProvider>
                  <QueryProvider
                    revalidateOnInit={props.revalidateOnInit}
                    externalApiHost={props.externalApiHost}
                    externalApiBasePath={props.externalApiBasePath}
                  >
                    <PortalProvider>
                      <GeolocationProvider>
                        <PromptProvider>
                          <ErrorBoundary level="fatal">{children}</ErrorBoundary>
                          <PORTALS.Common />
                        </PromptProvider>
                      </GeolocationProvider>
                    </PortalProvider>
                  </QueryProvider>
                </LayoutProvider>
              </Scrollable>
            </IntlProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </MediaProvider>
    </ErrorBoundaryProvider>
  );
};

export type { SharedProviderProps };
export { SharedProvider };
export default SharedProvider;
