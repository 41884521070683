import { useCallback, useEffect, useMemo } from 'react';

import { useAccountFulfillmentPreferences } from './use-account-fulfillment-preferences';

import { usePlatformOs } from 'common/use-cases/use-platform-os';

import { useStoreInfo } from 'common/use-cases/use-store-info';
import {
  SetAccountFulfillmentPreferencesEndpoint,
  SetAccountFulfillmentPreferencesParams,
} from 'endpoints/profile/preferences/set-account-fulfillment';

import { FullShopFulfillmentType, isDelivery } from 'entities/shop/info';

const usePickupDeliverySettings = () => {
  const currentStore = useStoreInfo();
  const {
    data,
    mutate,
    isLoading,
    orderType,
    availableFulfillment,
    isSelectedMethodAvailable,
    isPickupDeliveryAvailable,
    storeConfig,
  } = useAccountFulfillmentPreferences();

  const saveFulfillmentPreferences = useCallback(
    async (value: SetAccountFulfillmentPreferencesParams) => {
      try {
        await SetAccountFulfillmentPreferencesEndpoint.request(value);
        await mutate();
      } catch (e) {
        console.error(e);
      }
    },
    [mutate],
  );

  const { platformOs } = usePlatformOs();

  useEffect(() => {
    if (!isSelectedMethodAvailable) {
      const [availableFulfillmentType] = availableFulfillment;
      if (availableFulfillmentType) {
        void saveFulfillmentPreferences({
          preferencesStoreId: storeConfig.id,
          fulfillmentType: isDelivery(availableFulfillmentType as unknown as FullShopFulfillmentType)
            ? FullShopFulfillmentType.Delivery
            : FullShopFulfillmentType.InStorePickup,
          platformOs,
        });
      }
    }
  }, [availableFulfillment, storeConfig.id, isSelectedMethodAvailable, saveFulfillmentPreferences, platformOs]);

  const deliveryLocation = useMemo(() => {
    return data && data.deliveryLongitude && data.deliveryLatitude
      ? {
          lng: data.deliveryLongitude,
          lat: data.deliveryLatitude,
        }
      : undefined;
  }, [data]);

  return {
    orderType,
    currentStoreId: storeConfig.id,
    currentStore,
    deliveryAddress: data?.deliveryAddress ?? undefined,
    deliveryAddressSecondLine: data?.deliverySecondaryAddress || undefined,
    deliveryLocation,
    availableFulfillment,
    isPickupDeliveryAvailable,
    saveFulfillmentPreferences,
    isCaregiverOrdersEnabled: currentStore.isCaregiverOrdersEnabled,
    isLoading,
  };
};

export { usePickupDeliverySettings };
