import { useCallback } from 'react';

import { DEFAULT_QUERY_PARSER_CONFIG } from '@swe/shared/hooks/pagination/config';
import { QueryConfig } from '@swe/shared/hooks/pagination/types';
import { usePagination } from '@swe/shared/hooks/pagination/use-pagination';
import { buildLink } from '@swe/shared/hooks/pagination/utils/link-builder';
import { QueryParserConfig } from '@swe/shared/hooks/pagination/utils/query-parser';
import {
  Endpoint,
  GetEndpointUseRequestParams,
  GetEndpointUseRequestReturn,
} from '@swe/shared/network/transport/query/transport.types';
import { PaginatedRequest, PaginatedResponse } from '@swe/shared/types/wrappers/pagination';

type UsePaginatedDataConfig<
  Params extends PaginatedRequest,
  Data extends PaginatedResponse<any>,
  DataSource extends Endpoint<any, Params, Data, any>,
> = {
  endpoint: DataSource;
  params: Omit<Exclude<GetEndpointUseRequestParams<DataSource>, undefined | null>, keyof PaginatedRequest> | null;
  onPaginationChanged?: () => void;
  queryParserConfig?: QueryParserConfig;
  linkBuilderConfig?: Partial<QueryConfig> & { pathname: string };
};

const usePaginated = <
  Params extends PaginatedRequest,
  Data extends PaginatedResponse<any>,
  DataSource extends Endpoint<any, Params, Data, any>,
>({
  endpoint,
  params,
  onPaginationChanged,
  queryParserConfig = DEFAULT_QUERY_PARSER_CONFIG,
  linkBuilderConfig,
}: UsePaginatedDataConfig<Params, Data, DataSource>) => {
  const { pagination, onChange } = usePagination({ queryParserConfig });
  const requestParams = params ? { ...params, ...pagination } : null;
  const query = endpoint.useRequest(
    requestParams,
    {},
    { keepPreviousData: true },
  ) as GetEndpointUseRequestReturn<DataSource>;

  const buildPageLink = useCallback(
    (page: number) =>
      linkBuilderConfig
        ? buildLink({
            ...linkBuilderConfig,
            pageKey: queryParserConfig.pageKey,
            pageSizeKey: queryParserConfig.pageSizeKey,
            page,
          })
        : undefined,
    [linkBuilderConfig, queryParserConfig.pageKey, queryParserConfig.pageSizeKey],
  );

  const onPaginationChange = useCallback(
    (page: number, size?: number) => {
      onChange(page, size);
      onPaginationChanged?.();
    },
    [onChange, onPaginationChanged],
  );

  return {
    ...query,
    pagination,
    buildPageLink,
    total: query.data?.total ?? 0,
    pagesCount: query.data ? Math.ceil(query.data.total / pagination.pageSize) : 0,
    onPaginationChange,
  };
};

export type { UsePaginatedDataConfig };
export { usePaginated };
